export const sidebarData = {
    superadmin: [
        {
            id: 1,
            name: "Clients",
            subfields: [
                {
                    name: "List Clients",
                    href: "/clients",
                    permissions: [
                        "CREATE CLIENT",
                        "UPDATE CLIENT",
                        "READ CLIENT",
                        "DELETE CLIENT",
                    ],
                },
            ],
        },
        {
            id: 2,
            name: "Role system",
            subfields: [
                {
                    name: "List Role",
                    href: "/role",
                    permissions: [
                        "CREATE ROLE",
                        "UPDATE ROLE",
                        "READ ROLE",
                        "DELETE ROLE",
                    ],
                },
            ],
        },
        {
            id: 3,
            name: "Superadmin",
            subfields: [
                {
                    name: "List Superadmins",
                    href: "/super-admin",
                    permissions: [
                        "CREATE SUPERADMIN",
                        "READ SUPERADMIN",
                        "UPDATE SUPERADMIN",
                        "DELETE SUPERADMIN",
                    ],
                },
            ],
        },
    ],
    admin: [
        {
            id: 2,
            name: "Role System",
            subfields: [
                {
                    name: "List Role",
                    href: "/role",
                    permissions: [
                        "CREATE ROLE",
                        "UPDATE ROLE",
                        "READ ROLE",
                        "DELETE ROLE",
                    ],
                },
            ],
        },

        {
            id: 4,
            name: "Users",
            subfields: [
                {
                    name: "List Users",
                    href: "/user",
                    permissions: [
                        "CREATE ADMIN",
                        "UPDATE ADMIN",
                        "READ ADMIN",
                        "DELETE ADMIN",
                    ],
                },
            ],
        },

        {
            id: 3,
            name: "Campus",
            subfields: [
                {
                    name: "List Campuses",
                    href: "/campuses",
                    permissions: [
                        "CREATE CAMPUS",
                        "UPDATE CAMPUS",
                        "READ CAMPUS",
                        "DELETE CAMPUS",
                    ],
                },
                // {
                //     name: "Change Campus",
                //     href: "/change-campus",
                //     permissions: [
                //         "CREATE CAMPUS",
                //         "UPDATE CAMPUS",
                //         "READ CAMPUS",
                //         "DELETE CAMPUS",
                //     ],
                // },
            ],
        },

        {
            id: 5,
            name: "Academics",
            subfields: [
                {
                    name: "Session ",
                    href: "/session",
                    permissions: [
                        "CREATE SESSION",
                        "UPDATE SESSION",
                        "READ SESSION",
                        "DELETE SESSION",
                    ],
                },
                {
                    name: "Classes",
                    href: "/classroom",
                    permissions: [
                        "CREATE CLASSROOM",
                        "UPDATE CLASSROOM",
                        "READ CLASSROOM",
                        "DELETE CLASSROOM",
                    ],
                },
                {
                    name: "Section",
                    href: "/section",
                    permissions: [
                        "CREATE SECTION",
                        "UPDATE SECTION",
                        "READ SECTION",
                        "DELETE SECTION",
                    ],
                },
                {
                    name: "Publisher",
                    href: "/publisher",
                    permissions: [
                        "CREATE PUBLISHER",
                        "UPDATE PUBLISHER",
                        "READ PUBLISHER",
                        "DELETE PUBLISHER",
                    ],
                },
                {
                    name: "Subject",
                    href: "/subject",
                    permissions: [
                        "CREATE SUBJECT",
                        "UPDATE SUBJECT",
                        "READ SUBJECT",
                        "DELETE SUBJECT",
                    ],
                },
            ],
        },
        {
            id: 6,
            name: "Student",
            subfields: [
                {
                    name: "Addmission & View",
                    href: "/student",
                    permissions: [
                        "CREATE STUDENT",
                        "READ STUDENT",
                        "UPDATE STUDENT",
                        "DELETE STUDENT",
                    ],
                },

                {
                    name: "Update Student",
                    href: "/student/change-student",
                    permissions: ["UPDATE STUDENT"],
                },

                {
                    name: "Promote",
                    href: "/student/promote-section",
                    // permissions: ["PROMOTE STUDENT"],
                    permissions: ["PROMOTE STUDENT"],
                },

                {
                    name: "Delete Promotion",
                    href: "/student-delete-promotion",
                    // permissions: ["PROMOTE STUDENT"],
                    permissions: ["PROMOTE STUDENT"],
                },

                {
                    name: "Complete Student",
                    href: "/student-complete",
                    permissions: ["UPDATE STUDENT"],
                },

                {
                    name: "Categories",
                    href: "/student-category",
                    // permissions: [
                    //     "CREATE STUDENT_CATEGORY",
                    //     "READ STUDENT_CATEGORY",
                    //     "UPDATE STUDENT_CATEGORY",
                    //     "DELETE STUDENT_CATEGORY",
                    // ],
                    permissions: [
                        "CREATE STUDENT_CATEGORY",
                        "READ STUDENT_CATEGORY",
                        "UPDATE STUDENT_CATEGORY",
                        "DELETE STUDENT_CATEGORY",
                    ],
                },

                {
                    name: "Assign Student Categories",
                    href: "/assign-student-category",
                    permissions: ["CREATE STUDENT_CATEGORY"],
                },
                {
                    name: "Count Report",
                    href: "/student/count-report",
                    permissions: ["READ COUNT_REPORT"],
                },
                {
                    name: "Import From Excel",
                    href: "/import-from-excel",
                    permissions: ["IMPORT STUDENT"],
                },
                {
                    name: "Withdrawl Register",
                    href: "/student/withdrawl-register",
                    permissions: ["READ WITHDRAWL_REGISTER"],
                },
            ],
        },

        {
            id: 8,
            name: "Fees",
            subfields: [
                {
                    name: "Fee Category",
                    href: "/fee-category",
                    permissions: [
                        "CREATE FEE_CATEGORY",
                        "READ FEE_CATEGORY",
                        "UPDATE FEE_CATEGORY",
                        "DELETE FEE_CATEGORY",
                    ],
                },
                {
                    name: "Fee Structure",
                    href: "/fee-structure",
                    permissions: [
                        "CREATE FEE_STRUCTURE",
                        "READ FEE_STRUCTURE",
                        "UPDATE FEE_STRUCTURE",
                        "DELETE FEE_STRUCTURE",
                    ],
                },
                {
                    name: "Fee Discount",
                    href: "/fee-discount",
                    permissions: [
                        "CREATE FEE_DISCOUNT",
                        "READ FEE_DISCOUNT",
                        "UPDATE FEE_DISCOUNT",
                        "DELETE FEE_DISCOUNT",
                    ],
                },
                {
                    name: "Apply Fees",
                    href: "/apply-fees",
                    permissions: ["APPLY FEE"],
                },
                { name: "Fee List", href: "/fee", permissions: ["READ FEE"] },
                {
                    name: "Edit Fees",
                    href: "/edit-fees",
                    permissions: ["UPDATE FEE"],
                },
                {
                    name: "Collect Fees",
                    href: "/collect-fees",
                    permissions: ["COLLECT FEE"],
                },
                {
                    name: "Receipts",
                    href: "/fees/receipt/multiple",
                    permissions: ["GENERATE RECEIPT"],
                },
                {
                    name: "Fee Report",
                    href: "/fees/reports/fee-generated",
                    permissions: ["FEE REPORT"],
                },
                {
                    name: "Defaulters",
                    href: "/fees/reports/defaulters",
                    permissions: ["FEE DEFAULTER_REPORT"],
                },
                {
                    name: "View Collection",
                    href: "/fees/reports/daily-collection",
                    permissions: ["READ FEE_COLLECTION"],
                },
            ],
        },
        {
            id: 9,
            name: "Finance",
            subfields: [
                {
                    name: "Banks",
                    href: "/banks",
                    permissions: [
                        "CREATE BANK",
                        "UPDATE BANK",
                        "READ BANK",
                        "DELETE BANK",
                    ],
                },
            ],
        },
        {
            id: 10,
            name: "Family",
            subfields: [
                {
                    name: "Create",
                    href: "/family/create",
                    permissions: ["CREATE FAMILY"],
                },
                {
                    name: "Actions & Details",
                    href: "/family",
                    permissions: [
                        "READ FAMILY",
                        "UPDATE FAMILY",
                        "DELETE FAMILY",
                        "ADD MEMBER",
                        "REMOVE MEMBER",
                        "READ FAMILY_RECEIPT",
                    ],
                },
            ],
        },

        {
            id: 11,
            name: "Library",
            subfields: [
                {
                    name: "Authors",
                    href: "/library/authors",
                    permissions: [
                        "CREATE AUTHOR",
                        "READ AUTHOR",
                        "UPDATE AUTHOR",
                        "DELETE AUTHOR",
                    ],
                },

                {
                    name: "Publishers",
                    href: "/publisher",
                    permissions: [
                        "CREATE PUBLISHER",
                        "READ PUBLISHER",
                        "UPDATE PUBLISHER",
                        "DELETE PUBLISHER",
                    ],
                },

                {
                    name: "Genres",
                    href: "/library/genres",
                    permissions: [
                        "CREATE GENRES",
                        "READ GENRES",
                        "UPDATE GENRES",
                        "DELETE GENRES",
                    ],
                },

                {
                    name: "Books",
                    href: "/library/books",
                    permissions: [
                        "CREATE BOOK",
                        "READ BOOK",
                        "UPDATE BOOK",
                        "DELETE BOOK",
                    ],
                },

                {
                    name: "Stock",
                    href: "/library/stock",
                    permissions: [
                        "CREATE BOOK_INSTANCE",
                        "READ BOOK_INSTANCE",
                        "UPDATE BOOK_INSTANCE",
                        "DELETE BOOK_INSTANCE",
                    ],
                },

                {
                    name: "Issue / Return",
                    href: "/library/issue-return",
                    permissions: [
                        "CREATE LIBRARY_HISTORY",
                        "UPDATE LIBRARY_HISTORY",
                        "DELETE LIBRARY_HISTORY",
                        "READ LIBRARY_HISTORY",
                    ],
                },

                {
                    name: "History",
                    href: "/library/history",
                    permissions: [
                        "CREATE LIBRARY_HISTORY",
                        "READ LIBRARY_HISTORY",
                    ],
                },
            ],
        },

        {
            id: 12,
            name: "Certificates",
            subfields: [
                {
                    name: "List Certificates",
                    href: "/user/certificate",
                    permissions: [
                        "CREATE CERTIFICATE",
                        "READ CERTIFICATE",
                        "UPDATE CERTIFICATE",
                        "DELETE CERTIFICATE",
                    ],
                },

                {
                    name: "Assign Certificate",

                    permissions: ["READ CERTIFICATE_HISTORY"],
                    href: "/user/certificate/assign",
                },
            ],
        },

        {
            id: 13,
            name: "Test",
            subfields: [
                {
                    name: "List Test",
                    href: "/test",
                    permissions: [
                        "CREATE TEST_RESULT",
                        "READ TEST_RESULT",
                        "UPDATE TEST_RESULT",
                        "DELETE TEST_RESULT",
                    ],
                },

                {
                    name: "Test Results",
                    permissions: ["READ TEST_RESULT"],
                    href: "/test-result",
                },
            ],
        },

        {
            id: 14,
            name: "Attendance",
            subfields: [
                {
                    name: "List Attendance",
                    permissions: ["READ ATTENDANCE"],
                    href: "/attendance",
                },
                {
                    name: "Create Attendance",
                    href: "/add-student-attendance",
                    permissions: [
                        "CREATE ATTENDANCE",
                        "READ ATTENDANCE",
                        "UPDATE ATTENDANCE",
                        "DELETE ATTENDANCE",
                    ],
                },

                {
                    name: "Update Attendance",
                    permissions: ["UPDATE ATTENDANCE"],
                    href: "/update-student-attendance",
                },

                {
                    name: "% Age Attendance",
                    permissions: ["READ ATTENDANCE"],
                    href: "/attendance-percentage",
                },

                {
                    name: "OverAll Attendance",
                    permissions: ["READ ATTENDANCE"],
                    href: "/overall-attendance",
                },

                {
                    name: "Student Leave",
                    permissions: ["CREATE ATTENDANCE"],
                    href: "/student-leave",
                },

                {
                    name: "Blank Attendance List",
                    permissions: ["READ ATTENDANCE"],
                    href: "/student-blank-attendance-list",
                },
            ],
        },

        {
            id: 13,
            name: "Logs",
            subfields: [
                {
                    name: "List Logs",
                    href: "/logs",
                    permissions: ["READ LOGS"],
                },
            ],
        },
    ],
    faculty: [],
    student: [],
};
