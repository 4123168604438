import { ClearAll } from "@mui/icons-material";
import { Button, Grid, IconButton, TextField, Tooltip } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import BaseAccordian from "../../../BaseAccordian";
import { SessionService } from "../../../services/SessionService";
import { StudentAttendanceService } from "../../../services/StudentAttendanceService";
import { AppContext } from "../../AppContext";
import { Autocomplete } from "../../AutoComplete";
import { RUDTable } from "../../Table/RUDTable";

const StudentPercentageAttendance = () => {
    const { user } = useContext(AppContext);
    const [lastSession, setLastSession] = useState<any>();

    const [filter, setFilter] = useState<any>({
        search: "",
        campus:
            user?.baseUser?.userRegions?.length > 1
                ? ""
                : user?.baseUser?.userRegions[0]?.campus?.id,
        status: "",
        subject: "",
        student: "",
        startDate: new Date().toISOString().split("T")[0],
        endDate: new Date().toISOString().split("T")[0],
        apply: 0,
    });
    const [clearFilters, setClearFilters] = useState(false);

    const handleFilterClear = () => {
        setClearFilters(!clearFilters);
        setFilter({
            search: "",
            campus: "",
            status: "",
            student: "",
            subject: "",
            apply: 0,
        });
    };
    const LastSession = async () => {
        try {
            const [data, err] = await SessionService.getLastSession(1, 1, {
                campus: filter.campus ? filter.campus : null,
            });

            if (err) {
                console.error("Error fetching last session:", err);
                return;
            }

            if (data && data.rows.length) {
                setFilter((prevData: any) => ({
                    ...prevData,
                    session: data.rows[0].id,
                }));
                setLastSession(data.rows[0]);
            } else {
                console.warn("No session data found.");
            }
        } catch (error) {
            console.error("Unexpected error in LastSession:", error);
        }
    };

    const [changes, setChanges] = useState(0);
    useEffect(() => {
        if (filter.campus) {
            LastSession();
        }
    }, [filter?.campus as any]);

    return (
        <div>
            <BaseAccordian>
                <Grid container spacing={1.5}>
                    {user?.baseUser?.userRegions?.length > 1 && (
                        <Grid item xs={12} md={4}>
                            <Autocomplete
                                api="/org/campus"
                                setOutput={(c: any) =>
                                    setFilter({
                                        ...filter,
                                        campus: c?.id || "",
                                    })
                                }
                                label="Campus"
                                labelKey="name"
                                textFieldProps={{
                                    variant: "outlined",
                                    size: "small",
                                }}
                                clear={clearFilters}
                            />
                        </Grid>
                    )}

                    <Grid item xs={12} md={4}>
                        <Autocomplete
                            api="/org/session"
                            setOutput={(c: any) =>
                                setFilter({
                                    ...filter,
                                    session: c.id || "",
                                })
                            }
                            label="Session"
                            labelKey="name"
                            textFieldProps={{
                                variant: "outlined",
                                size: "small",
                                required: true,
                            }}
                            clear={clearFilters}
                            apiParams={{
                                campus: filter.campus,
                                status: "active",
                            }}
                            key={lastSession?.id}
                            defaultValue={lastSession}
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Autocomplete
                            api="/org/academics/classroom"
                            setOutput={(c: any) =>
                                setFilter({
                                    ...filter,
                                    classroom: c?.id || "",
                                })
                            }
                            label="Class"
                            labelKey="name"
                            textFieldProps={{
                                variant: "outlined",
                                size: "small",
                                required: true,
                            }}
                            clear={clearFilters}
                            apiParams={{
                                campus: filter.campus,
                                status: "active",
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Autocomplete
                            api="/org/academics/section"
                            setOutput={(c: any) =>
                                setFilter({
                                    ...filter,
                                    section: c.id || "",
                                })
                            }
                            label="Section"
                            labelKey="name"
                            textFieldProps={{
                                variant: "outlined",
                                size: "small",
                                required: true,
                            }}
                            clear={clearFilters}
                            apiParams={{
                                classroom: filter.classroom,
                                status: "active",
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Autocomplete
                            defaultOptions={[
                                {
                                    label: "Attendance By Day",
                                    status: "dayWise",
                                    id: "dayWise",
                                },
                                {
                                    label: "Attendance By Subject",
                                    status: "subject",
                                    id: "subject",
                                },
                            ]}
                            setOutput={(c: any) =>
                                setFilter({
                                    ...filter,
                                    type: c?.id || "",
                                })
                            }
                            label="Attendance Type"
                            labelKey="label"
                            textFieldProps={{
                                variant: "outlined",
                                size: "small",
                                required: true,
                            }}
                            clear={clearFilters}
                        />
                    </Grid>

                    {filter?.type == "subject" && (
                        <Grid item xs={12} md={4}>
                            <Autocomplete
                                api="/org/Academics/Subject"
                                setOutput={(c: any) =>
                                    setFilter({
                                        ...filter,
                                        subject: c?.id || "",
                                    })
                                }
                                label="Subject"
                                labelKey="name"
                                textFieldProps={{
                                    variant: "outlined",
                                    size: "small",
                                }}
                                clear={clearFilters}
                                apiParams={{
                                    classroom: filter.classroom,
                                    status: "active",
                                }}
                            />
                        </Grid>
                    )}

                    <Grid item xs={3} md={4}>
                        <TextField
                            type="date"
                            size="small"
                            fullWidth
                            label="From Date"
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) =>
                                setFilter({
                                    ...filter,
                                    startDate: e.target.value,
                                })
                            }
                            defaultValue={
                                new Date().toISOString().split("T")[0]
                            }
                        />
                    </Grid>

                    <Grid item xs={3} md={4}>
                        <TextField
                            type="date"
                            size="small"
                            fullWidth
                            label="To Date"
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) =>
                                setFilter({
                                    ...filter,
                                    endDate: e.target.value,
                                })
                            }
                            variant="outlined"
                            defaultValue={
                                new Date().toISOString().split("T")[0]
                            }
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Button
                            color="primary"
                            fullWidth
                            variant="contained"
                            onClick={() =>
                                setFilter({
                                    ...filter,
                                    apply: filter.apply + 1,
                                })
                            }
                            style={{ height: "100%" }}
                            size="small"
                        >
                            {" "}
                            Search
                        </Button>
                    </Grid>

                    <Grid item xs={12} md={6} lg={3}>
                        <Tooltip title="Clear Filters">
                            <IconButton
                                onClick={handleFilterClear}
                                color="primary"
                            >
                                <ClearAll />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </BaseAccordian>

            <RUDTable
                getter={(page, limit) =>
                    StudentAttendanceService.getAttendancePercentage(
                        page,
                        limit,
                        {
                            search: filter.search,
                            attendanceDate: filter.attendanceDate,
                            subject:
                                filter.type == "subject"
                                    ? filter.subject
                                    : undefined,
                            user: filter.user,
                            campus: filter.campus,
                            classroom: filter.classroom,
                            type: filter.type,
                            session: filter.session,
                            section: filter.section,
                            startDate: filter.startDate,
                            endDate: filter.endDate,
                            attendanceStatus: filter.attendanceStatus,
                            student: filter.student,
                        }
                    )
                }
                rowsPreprocessor={(row) => ({
                    ...row,
                    deletionId: row.id,
                    total: row.total,
                    Present: row.present.count,
                    PresentPercentage: row.present.percentage,
                    Absent: row.absent.count,
                    AbsentPercentage: row.absent.percentage,
                    Leave: row.leave.count,
                    LeavePercentage: row.leave.percentage,
                    Late: row.late.count,
                    LatePercentage: row.late.percentage,
                    offDay: row.offDay.count,
                    offDayPercentage: row.offDay.percentage,
                    sickLeave: row.sickLeave.count,
                    sickLeavePercentage: row.sickLeave.percentage,
                })}
                readables={{
                    total: "Total",
                    Present: "Present",
                    PresentPercentage: "Present %age",
                    Absent: "Absent",
                    AbsentPercentage: "Absent %age",
                    Leave: "Leave",
                    LeavePercentage: "Leave %age",
                    Late: "Late",
                    LatePercentage: "Late %age",
                    offDay: "Off Day",
                    offDayPercentage: "OffDay %age",
                    sickLeave: "sickLeave",
                    sickLeavePercentage: "SickLeave %age",
                }}
                editables={[]}
                customCols={[]}
                updatingAgents={[filter.apply, filter.search, changes]}
                ops={{
                    read: "READ ATTENDANCE",
                    edit: "",
                    delete: "DELETE ATTENDANCE",
                }}
                storageKey="attendance Percentage"
                unsortableCols={[
                    "total",
                    "Present",
                    "PresentPercentage",
                    "Absent",
                    "AbsentPercentage",
                    "Leave",
                    "LeavePercentage",
                    "Late",
                    "LatePercentage",
                    "offDay",
                    "offDayPercentage",
                    "sickLeave",
                    "sickLeavePercentage",
                ]}
            />
        </div>
    );
};

export default StudentPercentageAttendance;
