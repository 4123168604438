import { ClearAll, Redo, Undo } from "@mui/icons-material";
import {
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineSeparator,
} from "@mui/lab/";
import {
    Button,
    Grid,
    IconButton,
    Tab,
    TablePagination,
    Tabs,
    Tooltip,
    Typography,
} from "@mui/material";
import { useContext, useState } from "react";
import { BookHistoryService } from "../../services/BookHistoryService";
import { useGetAPI } from "../../services/Hooks";
import { AppContext } from "../AppContext";
import { Autocomplete } from "../AutoComplete";
import { baseAPI, dateFormatter } from "../constants";
import { RUDTable } from "../Table/RUDTable";

type Filter = {
    campus: number | string;
    student?: number | string;
    book?: number | string;

    [key: string]: any;
};

type history = "normal" | "graphical";
const BookHistory = () => {
    const [sort, setSort] = useState<{ column: ""; direction: "" }>();
    const [activeTab, setActiveTab] = useState<history>("normal");
    const { user } = useContext(AppContext);

    const [filter, setFilter] = useState<Filter>({
        campus:
            user?.baseUser?.userRegions?.length > 1
                ? ""
                : user?.baseUser?.userRegions[0]?.campus?.id,
        student: "",
        book: "",
        apply: 0,
    });
    const [pagination, setPagination] = useState({
        page: 0,
        limit: 10,
    });

    const [clearFilters, setClearFilters] = useState(false);

    const handleFilterClear = () => {
        setClearFilters(!clearFilters);
        setFilter({
            campus:
                user?.baseUser?.userRegions?.length > 1
                    ? ""
                    : user?.baseUser?.userRegions[0]?.campus?.id,
            student: "",
            book: "",
            apply: 0,
        });
    };

    const handlePageChange = (ev: any, page: number) => {
        setPagination({ ...pagination, page: page });
    };

    const handleRowsPerPageChange = (ev: any) => {
        setPagination({ ...pagination, limit: ev.target.value });
    };

    const [history, count, status] = useGetAPI(
        `${baseAPI}/library/library-history`,
        pagination.page + 1,
        pagination.limit,
        {
            baseUser: filter.student ? filter.student : undefined,
            bookInstance: filter.book ? filter.book : undefined,
            column: sort?.column ? sort.column : undefined,
            direction: sort?.direction ? sort.direction : undefined,
        },
        [filter.apply, pagination, sort?.direction]
    );

    return (
        <Grid container style={{ display: "flex", justifyContent: "center" }}>
            <Grid item container spacing={1}>
                {user?.baseUser?.userRegions?.length > 1 && (
                    <Grid item xs={12} md={4}>
                        <Autocomplete
                            api="/org/campus"
                            setOutput={(c: any) =>
                                setFilter({
                                    ...filter,
                                    campus: c?.id || "",
                                })
                            }
                            label="Campus"
                            labelKey="name"
                            textFieldProps={{
                                variant: "outlined",
                                size: "small",
                            }}
                            clear={clearFilters}
                        />
                    </Grid>
                )}
                <Grid item xs={12} md={4}>
                    <Autocomplete
                        api="/org/student"
                        setOutput={(c: any) =>
                            setFilter({
                                ...filter,
                                student: c?.id || "",
                            })
                        }
                        label="Student"
                        labelKey="name"
                        textFieldProps={{
                            variant: "outlined",
                            size: "small",
                        }}
                        clear={clearFilters}
                        processor={(opt) => ({
                            ...opt,
                            name: opt?.baseUser?.name,
                            id: opt?.baseUser?.id,
                        })}
                        apiParams={{
                            campus: filter.campus ? filter.campus : "",
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Autocomplete
                        api="/library/book-instance"
                        setOutput={(c: any) =>
                            setFilter({
                                ...filter,
                                book: c?.id || "",
                            })
                        }
                        label="Book"
                        labelKey="name"
                        textFieldProps={{
                            variant: "outlined",
                            size: "small",
                        }}
                        clear={clearFilters}
                        processor={(opt) => ({
                            ...opt,
                            name: `BK-${opt?.id}`,
                        })}
                        apiParams={{ campus: filter.campus }}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Button
                        color="primary"
                        fullWidth
                        variant="contained"
                        onClick={() =>
                            setFilter({
                                ...filter,
                                apply: filter.apply + 1,
                            })
                        }
                        size="small"
                    >
                        {" "}
                        Search
                    </Button>
                </Grid>{" "}
                <Grid item xs={12} md={4}>
                    <Tooltip title="Clear Filters">
                        <IconButton onClick={handleFilterClear} color="primary">
                            <ClearAll />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>

            <div>
                <Tabs
                    centered
                    textColor="primary"
                    indicatorColor="primary"
                    onChange={(e, newTab) => setActiveTab(newTab as history)}
                    value={activeTab}
                >
                    <Tab label="Normal" value="normal" />
                    <Tab label="Graphical" value="graphical" />
                </Tabs>
            </div>

            {activeTab == "normal" ? (
                <div style={{ width: "100%" }}>
                    <RUDTable
                        getter={(page, limit) =>
                            BookHistoryService.getHistory(page, limit, {
                                search: filter.search ? filter.search : null,
                                baseUser: filter.student
                                    ? filter.student
                                    : undefined,
                                bookInstance: filter.book
                                    ? filter.book
                                    : undefined,
                                column: sort?.column ? sort.column : undefined,
                                direction: sort?.direction
                                    ? sort.direction
                                    : undefined,
                            })
                        }
                        deletor={undefined}
                        rowsPreprocessor={(row) => ({
                            ...row,
                            deletionId: row.id,
                            bookName: row.bookInstance.book.name,
                            operatorName: row.operator.name,
                            returnDate: row.returnDate
                                ? dateFormatter.format(new Date(row.returnDate))
                                : "N/A",
                            issueDate: row.issueDate
                                ? dateFormatter.format(new Date(row.issueDate))
                                : null,
                            issueTo: row.baseUser.name,
                            acessionNo: `BK-${row.bookInstance.id}`,
                        })}
                        readables={{
                            issueTo: "IssueTo",
                            issueDate: "IssueDate",
                            returnDate: "Return Date",
                            bookName: "Book Name",
                            operatorName: "Operator Name",
                            acessionNo: "AcessionNo",
                        }}
                        updatingAgents={[
                            filter.apply,
                            filter.search,
                            sort?.direction,
                        ]}
                        ops={{
                            read: "READ SESSION",
                            edit: "",
                            delete: "DELETE SESSION",
                        }}
                        storageKey="history"
                        customCols={[]}
                        onSort={(row: any) => {
                            setSort(row);
                        }}
                    />
                </div>
            ) : (
                <>
                    {" "}
                    <Grid item xs={12}>
                        <Timeline position="alternate">
                            {history?.map((item: any, id: number) => (
                                <TimelineItem key={id}>
                                    <TimelineSeparator>
                                        <TimelineDot color="primary">
                                            {item.returnDate === null ? (
                                                <Redo />
                                            ) : (
                                                <Undo />
                                            )}
                                        </TimelineDot>
                                        <TimelineConnector />
                                    </TimelineSeparator>

                                    <TimelineContent>
                                        <Typography variant="h6">
                                            {item.bookInstance.book.name}
                                        </Typography>

                                        <Typography variant="body2">
                                            {item.returnDate === null
                                                ? "Issued To : " +
                                                  item.baseUser.name
                                                : "Returned By : " +
                                                  item.baseUser.name}
                                        </Typography>

                                        <Typography variant="body2">
                                            {item.returnDate === null
                                                ? "Issue Date : " +
                                                  new Date(
                                                      item.issueDate
                                                  ).toDateString()
                                                : "Returned Date : " +
                                                  new Date(
                                                      item.returnDate
                                                  ).toDateString()}
                                        </Typography>

                                        <Typography variant="body2">
                                            Duration :{" "}
                                            {new Date(
                                                item.duration
                                            ).toDateString()}
                                        </Typography>

                                        <Typography variant="body2">
                                            Operator : {item.operator.name}
                                        </Typography>
                                    </TimelineContent>
                                </TimelineItem>
                            ))}
                        </Timeline>
                    </Grid>
                    <Grid item>
                        <TablePagination
                            onPageChange={handlePageChange}
                            onRowsPerPageChange={handleRowsPerPageChange}
                            count={count}
                            rowsPerPage={pagination.limit}
                            component="div"
                            page={pagination.page}
                        />
                    </Grid>
                </>
            )}
        </Grid>
    );
};

export default BookHistory;
