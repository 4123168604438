import { ClearAll, Public, VpnKeyOff } from "@mui/icons-material";
import {
    Button,
    Dialog,
    DialogContent,
    Grid,
    IconButton,
    Tooltip,
    Typography,
} from "@mui/material";
import { useContext, useEffect, useMemo, useState } from "react";
import BaseAccordian from "../../../BaseAccordian";
import { MultiPermissionAuthorize } from "../../../MultiPermissionAuthorize";
import { UserService } from "../../../services/UserService";
import AddView from "../../AddView";
import { AppContext } from "../../AppContext";
import { Autocomplete } from "../../AutoComplete";
import { Search } from "../../Filters/Search";
import { RUDTable } from "../../Table/RUDTable";
import FeedbackSnackbar from "../../TestResult/FeedBackSnackBar";
import { awsDirStruct } from "../../constants";
import AddUser from "./AddUser";
import AttentionDialog from "./AttentionDialog";
import DeleteUser from "./DeleteUser";
import { UserRegionsTree } from "./UserRegionsTree";

type Domain = { id: number; name: string };

type Domains = {
    campus: Domain;
    classroom: Domain | null;
    section: Domain | null;
};

export default function User() {
    const [filter, setFilter] = useState<any>({
        search: "",
        role: "",
        apply: 0,
    });
    const [userSorting, setUserSorting] = useState<any>();
    const [showWithDelay, setShowWithDelay] = useState(false);
    const [clearFilters, setClearFilters] = useState(false);
    const [showDomains, setShowDomains] = useState(false);
    const [changes, setChanges] = useState(0);
    const [activeUser, setActiveUser] = useState<null | any>(null);
    const { user, feedback, setFeedback, dialogOpen, setDialogOpen } =
        useContext(AppContext);

    const getGroupedRegions = useMemo(() => {
        if (activeUser?.id) {
            const activeUserDomains: Domains[] =
                activeUser.baseUser.userRegions || [];

            const targetRegions = activeUserDomains.reduce(
                (result: any, item) => {
                    const { campus, classroom, section } = item;

                    // Group by campus
                    const campusKey = campus.name;
                    if (!result[campusKey]) {
                        result[campusKey] = {
                            classrooms: {},
                        };
                    }

                    // Group by classroom
                    if (classroom) {
                        const classroomKey = classroom.name;
                        if (!result[campusKey].classrooms[classroomKey]) {
                            result[campusKey].classrooms[classroomKey] = {
                                sections: [],
                            };
                        }

                        // Group by section
                        if (section) {
                            result[campusKey].classrooms[
                                classroomKey
                            ].sections.push(section.name);
                        }
                    }

                    return result;
                },
                {}
            );

            return targetRegions;
        }
    }, [activeUser]);

    const handleFilterClear = () => {
        setClearFilters(!clearFilters);
        setFilter({
            search: "",
            role: "",
            apply: 0,
        });
    };

    useEffect(() => {
        let timer: any;
        if (feedback.show) {
            // Start the delay timer
            timer = setTimeout(() => {
                setShowWithDelay(true);
            }, 500); // Adjust the delay time in milliseconds
        } else {
            // Reset visibility when feedback.show is false
            setShowWithDelay(false);
        }

        return () => clearTimeout(timer); // Clean up the timer
    }, [feedback.show]);

    return (
        <div>
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Typography variant="h4" color="primary" gutterBottom>
                        Users
                    </Typography>
                </Grid>

                <Grid>
                    <MultiPermissionAuthorize ops={["CREATE ADMIN"]}>
                        <AddView
                            label="add user"
                            maxWidth="md"
                            triggerRowsRefetch={() => setChanges(changes + 1)}
                        >
                            <AddUser />
                        </AddView>
                    </MultiPermissionAuthorize>
                </Grid>
            </Grid>

            <BaseAccordian>
                <Grid container spacing={1.5}>
                    <Grid item xs={12} md={6} lg={3}>
                        <Autocomplete
                            api="/rolesystem/role"
                            setOutput={(r) =>
                                setFilter({
                                    ...filter,
                                    role: r?.id || "",
                                })
                            }
                            label="Role"
                            labelKey="name"
                            textFieldProps={{
                                variant: "outlined",
                                size: "small",
                            }}
                            clear={clearFilters}
                        />
                    </Grid>

                    <Grid item xs={12} md={6} lg={3}>
                        <Search
                            filter={filter}
                            setFilter={setFilter}
                            clear={clearFilters}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <Button
                            color="primary"
                            fullWidth
                            variant="contained"
                            onClick={() =>
                                setFilter({
                                    ...filter,
                                    apply: filter.apply + 1,
                                })
                            }
                            style={{ height: "100%" }}
                            size="small"
                        >
                            {" "}
                            Search
                        </Button>
                    </Grid>

                    <Grid item xs={12} md={6} lg={3}>
                        <Tooltip title="Clear Filters">
                            <IconButton
                                onClick={handleFilterClear}
                                color="primary"
                            >
                                <ClearAll />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </BaseAccordian>

            <RUDTable
                getter={(page, limit) =>
                    UserService.getUsers(page, limit, {
                        search: filter.search ? filter.search : undefined,
                        role: filter.role ? filter.role : undefined,
                        direction: userSorting.direction
                            ? userSorting.direction
                            : undefined,
                        column: userSorting.column
                            ? userSorting.column
                            : undefined,
                    })
                }
                editor={UserService.editUser}
                rowsPreprocessor={(row) => ({
                    ...row,
                    deletionId: row.baseUser.id,
                    name: row.baseUser.name,
                    type: row.baseUser.type,
                    email: row.email,
                    s3Key: `${awsDirStruct.profile.picture}/${row.baseUser.username}`,
                    role: row.baseUser.role.name,
                    id: row.id,

                    username: user?.baseUser?.ops.includes(
                        "READ ADMIN_CREDENTIALS"
                    ) ? (
                        row.baseUser.username
                    ) : (
                        <Tooltip title="No Access to view username">
                            <VpnKeyOff color="error" fontSize="small" />
                        </Tooltip>
                    ),
                    password: user?.baseUser?.ops.includes(
                        "READ ADMIN_CREDENTIALS"
                    ) ? (
                        row.baseUser.password
                    ) : (
                        <Tooltip title="No Access to view password">
                            <VpnKeyOff color="error" fontSize="small" />
                        </Tooltip>
                    ),
                })}
                readables={{
                    id: "ID",
                    name: "Name",
                    type: "Type",
                    email: "Email",
                    role: "Role Name",
                    username: "Username",
                    password: "Password",
                    // ProfilePicture: "Profile Picture",
                    Domains: "Domains",
                    Edit: "Edit",
                    Delete: "Delete",
                }}
                ops={{
                    read: "READ ADMIN",
                    edit: "",
                    delete: "",
                }}
                customCols={[
                    // {
                    //     header: "ProfilePicture",
                    //     content: (row) => <ImagePreview row={row} />,
                    // },

                    {
                        header: "Domains",
                        content: (row) => (
                            <>
                                <IconButton
                                    disabled={row.baseUser.allRegionAccess}
                                    onClick={
                                        !row.baseUser.allRegionAccess
                                            ? () => setActiveUser(row)
                                            : undefined
                                    }
                                    size="small"
                                >
                                    {row.baseUser.allRegionAccess ? (
                                        <Public
                                            color="success"
                                            fontSize="small"
                                        />
                                    ) : (
                                        <Public fontSize="small" />
                                    )}
                                </IconButton>

                                <Dialog
                                    open={Boolean(activeUser?.id)}
                                    onClose={() => setActiveUser(null)}
                                    fullWidth
                                >
                                    <DialogContent>
                                        <UserRegionsTree
                                            regions={getGroupedRegions ?? {}}
                                        />
                                    </DialogContent>
                                </Dialog>
                            </>
                        ),
                    },

                    {
                        header: user?.baseUser?.ops.includes("UPDATE ADMIN")
                            ? "Edit"
                            : null,
                        content: (row) => (
                            <MultiPermissionAuthorize ops={["UPDATE ADMIN"]}>
                                <AddView
                                    maxWidth="md"
                                    label="Edit User"
                                    updateMode
                                    triggerRowsRefetch={() =>
                                        setChanges(changes + 1)
                                    }
                                >
                                    <AddUser row={row} updateMode />
                                </AddView>
                            </MultiPermissionAuthorize>
                        ),
                    },
                    {
                        header: user?.baseUser?.ops.includes("DELETE ADMIN")
                            ? "Delete"
                            : "",

                        content: (row) => (
                            <MultiPermissionAuthorize ops={["DELETE ADMIN"]}>
                                <DeleteUser
                                    row={row}
                                    changes={changes}
                                    setChanges={setChanges}
                                />
                            </MultiPermissionAuthorize>
                        ),
                    },
                ]}
                updatingAgents={[
                    changes,
                    user?.baseUser,
                    filter.apply,
                    userSorting?.direction,
                ]}
                storageKey="user"
                onSort={(row) => {
                    setUserSorting(row);
                }}
                unsortableCols={["type", "Domains", "Edit", "Delete"]}
            />

            {showWithDelay && (
                <FeedbackSnackbar
                    feedback={feedback}
                    setFeedback={setFeedback}
                />
            )}

            <AttentionDialog
                dialogOpen={dialogOpen}
                setDialogOpen={setDialogOpen}
            />
        </div>
    );
}
