import {
    Alert,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    FormControl,
    Grid,
    Autocomplete as MuiAutoComplete,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
} from "@mui/material";
import { ChangeEvent, useContext, useEffect, useMemo, useState } from "react";
import { usePostAPI } from "../../services/Hooks";
import { SessionService } from "../../services/SessionService";
import { StudentService } from "../../services/StudentService";
import { AddViewContext } from "../AddView";
import { AppContext } from "../AppContext";
import { Autocomplete } from "../AutoComplete";
import { baseAPI } from "../constants";
import FeedbackSnackbar from "./FeedBackSnackBar";
import { TestResultRow } from "./TestResultRow";

const columnOptions = [
    { label: "Reg No.", value: "admissionNumber" },
    { label: "Enroll No.", value: "enrollmentNo" },
    { label: "File No.", value: "fileNo" },
    { label: "Name", value: "name" },
    { label: "Father Name", value: "fatherName" },
];

const orderOptions = [
    { label: "Ascending", value: "ASC" },
    { label: "Descending", value: "DESC" },
];

const AddTestResult = () => {
    const { user, feedback, setFeedback } = useContext(AppContext);
    const { open, setOpen, triggerRowsRefetch } = useContext(AddViewContext);
    const [loading, setLoading] = useState<any>(null);
    const [lastSession, setLastSession] = useState<any>();
    const [selectedColumn, setSelectedColumn] = useState("admissionNumber");
    const [selectedOrder, setSelectedOrder] = useState("ASC");
    const [students, setStudents] = useState<any>();
    const [pagination, setPagination] = useState({
        page: 0,
        count: 0,
        limit: 100,
    });
    const [filter, setFilter] = useState<any>({
        campus:
            user?.baseUser?.userRegions?.length > 1
                ? ""
                : user?.baseUser?.userRegions[0]?.campus?.id,
        session: "",
        classroom: "",
        section: "",
        status: "",
        admissionStatus: "",
        apply: 0,
    });
    const [testResultInfo, setTestResultInfo] = useState({
        name: "",
        description: "",
        date: new Date().toISOString().split("T")[0],
        subject: "",
        employee: "",
        campus:
            user?.baseUser?.userRegions?.length > 1
                ? ""
                : user?.baseUser?.userRegions[0]?.campus?.id,
        totalMarks: "",
        classroom: "",
    });

    const [testResults, setTestResults] = useState<{
        [key: number]: any;
    }>({});

    const isAllChecked = useMemo(() => {
        const { page, limit } = pagination;

        const start = page * limit;
        const end = (page + 1) * limit - 1;

        const checked = Object.values(testResults)
            .slice(start, end)
            .every((x) => x.checked);

        return checked;
    }, [testResults]);

    const isSomeChecked = useMemo(() => {
        const { page, limit } = pagination;

        const start = page * limit;
        const end = (page + 1) * limit - 1;

        const checked =
            !isAllChecked &&
            Object.values(testResults)
                .slice(start, end)
                .some((x) => x.checked);

        return checked;
    }, [testResults]);

    function handleAllCheck(
        e: ChangeEvent<HTMLInputElement>,
        checked: boolean
    ) {
        setTestResults((curr) => {
            const allChecked = Object.fromEntries(
                Object.entries(curr).map(([k, v], index) => {
                    const { page, limit } = pagination;

                    const start = page * limit;
                    const end = (page + 1) * limit;

                    console.log(start, end);

                    if (index >= start && index < end) {
                        return [
                            k,
                            {
                                ...v,
                                checked,
                            },
                        ];
                    }

                    return [k, v];
                })
            );

            return allChecked;
        });
    }

    const getStudent = async () => {
        setLoading(true);
        const [data, err] = await StudentService.getStudent(
            pagination.page + 1,
            pagination.limit,
            {
                campus: testResultInfo.campus ? testResultInfo.campus : null,
                session: filter.session.id ? filter.session.id : null,
                classroom: testResultInfo.classroom
                    ? testResultInfo.classroom
                    : null,
                section: filter.section.id ? filter.section.id : null,
                column: selectedColumn,
                direction: selectedOrder,
            }
        );

        if (data?.rows?.length) {
            setStudents(data.rows);
            const studentData = data.rows.reduce((prev: any, curr: any) => {
                return {
                    ...testResults,
                    ...prev,
                    [curr?.baseUser?.id]: {
                        checked:
                            testResults[curr?.baseUser?.id]?.checked ?? false,
                        student: curr.id,
                    },
                };
            }, {});

            setTestResults(studentData);
            setPagination({
                ...pagination,
                count: data?.count ?? 0,
            });

            setLoading(false);
        } else {
            setStudents([]);
            setTestResults([]);
            setLoading(false);
        }
    };

    const [createTestResult, response, status, message] = usePostAPI(
        `${baseAPI}/org/test-result/create`,
        (response) => {
            setFeedback({
                ...feedback,
                respneseDetail: response,
                show: true,
                severity: "success",
                message: response.message,
            });

            triggerRowsRefetch();
            setOpen(false);
        },
        (errResponse) => {
            setFeedback({
                ...feedback,
                respneseDetail: errResponse,
                show: true,
                severity: "error",
                message: response.message,
            });
        }
    );

    async function handleSubmit(ev: any) {
        ev.preventDefault();
        setFeedback({ ...feedback, loading: true });
        const checkedResults = Object.values(testResults)
            .filter((payment) => payment.checked)
            .map((payment) => ({
                ...payment,
                marks: parseFloat(payment.marks), // Convert 'marks' to number
            }));

        if (!checkedResults || checkedResults.length == 0) {
            setFeedback({
                loading: false,
                message: "You must checked a row in order to create a test",
                show: true,
                severity: "error",
            });
        }
        if (checkedResults?.some((x) => x?.marks == null || isNaN(x?.marks))) {
            setFeedback({
                loading: false,
                message: "Must provide obtained marks for the student",
                show: true,
                severity: "error",
            });

            return;
        } else if (testResultInfo.name == "") {
            setFeedback({
                loading: false,
                message: "Must provide a test name in order to create a test",
                show: true,
                severity: "error",
            });
        } else if (testResultInfo.totalMarks == "") {
            setFeedback({
                loading: false,
                message: "Must provide a total marks in order to create a test",
                show: true,
                severity: "error",
            });
        } else if (testResultInfo.date == "") {
            setFeedback({
                loading: false,
                message: "Must provide a date in order to create a test",
                show: true,
                severity: "error",
            });
        } else if (testResultInfo.employee == "") {
            setFeedback({
                loading: false,
                message: "Must provide a employee in order to create a test",
                show: true,
                severity: "error",
            });
        } else if (testResultInfo.subject == "") {
            setFeedback({
                loading: false,
                message: "Must provide a subject in order to create a test",
                show: true,
                severity: "error",
            });
        } else if (filter.section == "") {
            setFeedback({
                loading: false,
                message: "Must provide a section in order to create a test",
                show: true,
                severity: "error",
            });
        } else {
            createTestResult(
                {
                    ...testResultInfo,
                    section: filter.section.id,
                    session: filter.session.id,
                    totalMarks: parseFloat(testResultInfo.totalMarks),
                    submissions: checkedResults,
                },
                {}
            );
        }
    }

    useEffect(() => {
        if (
            testResultInfo.campus &&
            filter.session &&
            filter.section &&
            testResultInfo.classroom &&
            selectedOrder
        ) {
            getStudent();
        }
    }, [
        filter.search,
        pagination.count,
        pagination.page,
        pagination.limit,
        filter.campus,
        filter.session,
        testResultInfo.classroom,
        filter.section,
        selectedOrder,
        selectedColumn,
    ]);

    const LastSession = async () => {
        try {
            const [data, err] = await SessionService.getLastSession(1, 1, {
                campus: testResultInfo.campus ? testResultInfo.campus : null,
            });

            if (err) {
                console.error("Error fetching last session:", err);
                return;
            }

            if (data && data.rows.length) {
                setFilter((prevFilter: any) => ({
                    ...prevFilter,
                    session: data.rows[0],
                }));
                setLastSession(data.rows[0]);
            } else {
                console.warn("No session data found.");
            }
        } catch (error) {
            console.error("Unexpected error in LastSession:", error);
        }
    };

    useEffect(() => {
        if (testResultInfo.campus) {
            LastSession();
        }
    }, [testResultInfo?.campus as any]);

    return (
        <div>
            <div>
                <Grid container style={{ paddingTop: "2rem" }} spacing={1}>
                    {user?.baseUser?.userRegions?.length > 1 && (
                        <Grid item xs={12} md={4}>
                            <Autocomplete
                                api="/org/campus"
                                setOutput={(c: any) =>
                                    setTestResultInfo({
                                        ...testResultInfo,
                                        campus: c?.id || "",
                                    })
                                }
                                label="Campus"
                                labelKey="name"
                                textFieldProps={{
                                    variant: "outlined",
                                    size: "small",
                                }}
                            />
                        </Grid>
                    )}

                    <Grid item xs={12} md={4}>
                        <Autocomplete
                            api="/org/session"
                            setOutput={(c: any) =>
                                setFilter({
                                    ...filter,
                                    session: c || "",
                                })
                            }
                            label="Session"
                            labelKey="name"
                            textFieldProps={{
                                variant: "outlined",
                                size: "small",
                            }}
                            apiParams={{
                                campus: testResultInfo.campus,
                                status: "active",
                            }}
                            defaultValue={lastSession}
                            key={lastSession?.id}
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Autocomplete
                            api="/org/academics/classroom"
                            setOutput={(c: any) =>
                                setTestResultInfo({
                                    ...testResultInfo,
                                    classroom: c?.id || "",
                                })
                            }
                            label="Class"
                            labelKey="name"
                            textFieldProps={{
                                variant: "outlined",
                                size: "small",
                            }}
                            apiParams={{
                                campus: testResultInfo.campus,
                                status: "active",
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Autocomplete
                            api="/org/academics/section"
                            setOutput={(c: any) =>
                                setFilter({
                                    ...filter,
                                    section: c || "",
                                })
                            }
                            label="Section"
                            labelKey="name"
                            textFieldProps={{
                                variant: "outlined",
                                size: "small",
                            }}
                            apiParams={{
                                classroom: testResultInfo.classroom,
                                status: "active",
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Autocomplete
                            api="/org/Academics/Subject"
                            setOutput={(c: any) =>
                                setTestResultInfo({
                                    ...testResultInfo,
                                    subject: c?.id || "",
                                })
                            }
                            label="Subject"
                            labelKey="name"
                            textFieldProps={{
                                variant: "outlined",
                                size: "small",
                            }}
                            apiParams={{
                                classroom: testResultInfo.classroom,
                                status: "active",
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Autocomplete
                            api="/org/user"
                            setOutput={(c: any) =>
                                setTestResultInfo({
                                    ...testResultInfo,
                                    employee: c?.id || "",
                                })
                            }
                            label="Employee"
                            labelKey="name"
                            textFieldProps={{
                                variant: "outlined",
                                size: "small",
                            }}
                            processor={(opt) => ({
                                ...opt,
                                name: opt?.baseUser?.name,
                                id: opt?.baseUser?.id,
                            })}
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <TextField
                            size="small"
                            label="Test Name"
                            fullWidth
                            value={testResultInfo.name}
                            name="name"
                            onChange={(ev) => {
                                setTestResultInfo({
                                    ...testResultInfo,
                                    name: ev.target.value,
                                });
                            }}
                            required
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <TextField
                            size="small"
                            label="Total Marks"
                            fullWidth
                            value={testResultInfo.totalMarks}
                            name="totalMarks"
                            onChange={(ev) => {
                                setTestResultInfo({
                                    ...testResultInfo,
                                    totalMarks: ev.target.value,
                                });
                            }}
                            required
                            type="number"
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ min: 0 }}
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <TextField
                            size="small"
                            label="Date"
                            fullWidth
                            value={testResultInfo.date}
                            name="date"
                            onChange={(ev) => {
                                setTestResultInfo({
                                    ...testResultInfo,
                                    date: ev.target.value,
                                });
                            }}
                            required
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            defaultValue={
                                new Date().toISOString().split("T")[0]
                            }
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <MuiAutoComplete
                            options={columnOptions}
                            getOptionLabel={(option) => option.label}
                            onChange={(event, newValue) =>
                                setSelectedColumn(newValue?.value || "")
                            }
                            value={columnOptions.find(
                                (option) => option.value === selectedColumn
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Sort By"
                                    variant="outlined"
                                />
                            )}
                            size="small"
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <MuiAutoComplete
                            options={orderOptions}
                            getOptionLabel={(option) => option.label}
                            onChange={(event, newValue) =>
                                setSelectedOrder(newValue?.value || "")
                            }
                            value={orderOptions.find(
                                (option) => option.value === selectedOrder
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Order"
                                    variant="outlined"
                                />
                            )}
                            size="small"
                            fullWidth
                        />
                    </Grid>
                </Grid>

                <Grid item xs={6}>
                    <TablePagination
                        component="div"
                        onPageChange={(ev, page) =>
                            setPagination({ ...pagination, page: page })
                        }
                        onRowsPerPageChange={(ev) =>
                            setPagination({
                                ...pagination,
                                limit: parseInt(ev.target.value),
                                page: 0,
                            })
                        }
                        count={pagination.count}
                        page={pagination.page}
                        rowsPerPage={pagination.limit}
                        rowsPerPageOptions={[100, 250, 500]}
                    />
                </Grid>

                {testResultInfo.campus == "" ||
                filter.session == "" ||
                testResultInfo.classroom == "" ||
                filter.section == "" ? (
                    <Alert severity="info">
                        Please select a campus session class and section{" "}
                    </Alert>
                ) : students?.length == 0 ? (
                    <Alert severity="info" style={{ width: "100%" }}>
                        No Student Found{" "}
                    </Alert>
                ) : loading ? (
                    <Box
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                    >
                        <CircularProgress />
                    </Box>
                ) : (
                    students && (
                        <form
                            onSubmit={(ev) => {
                                handleSubmit(ev);
                            }}
                            style={{ paddingTop: "3rem" }}
                        >
                            <Grid item xs={12}>
                                <TableContainer>
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow
                                                style={{ whiteSpace: "nowrap" }}
                                            >
                                                <TableCell>
                                                    <FormControl>
                                                        <Checkbox
                                                            checked={
                                                                isAllChecked
                                                            }
                                                            onChange={
                                                                handleAllCheck
                                                            }
                                                            indeterminate={
                                                                isSomeChecked
                                                            }
                                                        />
                                                    </FormControl>
                                                </TableCell>
                                                <TableCell>S No.</TableCell>
                                                <TableCell>Reg No.</TableCell>
                                                <TableCell>Enroll No</TableCell>
                                                <TableCell>File No</TableCell>
                                                <TableCell>Id</TableCell>
                                                <TableCell>Name</TableCell>
                                                <TableCell>
                                                    Father Name
                                                </TableCell>
                                                <TableCell>
                                                    Obtained Marks
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            {students &&
                                                students?.map(
                                                    (coll: any, id: number) => (
                                                        <TestResultRow
                                                            id={id + 1}
                                                            count={
                                                                id +
                                                                1 +
                                                                pagination.limit *
                                                                    pagination.page
                                                            }
                                                            key={id}
                                                            singleStudent={coll}
                                                            testResult={
                                                                testResults
                                                            }
                                                            setTestResult={
                                                                setTestResults
                                                            }
                                                            checkAll={
                                                                isAllChecked
                                                            }
                                                            totalMarks={
                                                                testResultInfo.totalMarks
                                                            }
                                                        />
                                                    )
                                                )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>

                            <div
                                style={{
                                    paddingTop: "1rem",
                                }}
                            >
                                <Button
                                    color="primary"
                                    variant="outlined"
                                    disabled={status === "loading"}
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    endIcon={
                                        status === "loading" ? (
                                            <CircularProgress size="1rem" />
                                        ) : null
                                    }
                                >
                                    {status === "loading"
                                        ? "Creating Test Result..."
                                        : "Create  Test Result"}
                                </Button>
                            </div>
                        </form>
                    )
                )}

                <FeedbackSnackbar
                    feedback={feedback}
                    setFeedback={setFeedback}
                />
            </div>
        </div>
    );
};

export default AddTestResult;
