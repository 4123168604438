import { ClearAll } from "@mui/icons-material";
import { Button, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { useState } from "react";
import BaseAccordian from "../../../BaseAccordian";
import { MultiPermissionAuthorize } from "../../../MultiPermissionAuthorize";
import { AuthorService } from "../../../services/AuthorService";
import AddView from "../../AddView";
import { Search } from "../../Filters/Search";
import { RUDTable } from "../../Table/RUDTable";
import AddAuthor from "./AddAuthor";

const Author = () => {
    const [filter, setFilter] = useState<any>({ search: "", apply: 0 });
    const [clearFilters, setClearFilters] = useState(false);
    const [changes, setChanges] = useState(0);
    const [sort, setSort] = useState<any>();

    const handleFilterClear = () => {
        setClearFilters(!clearFilters);
        setFilter({ search: "" });
    };

    return (
        <div>
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Typography variant="h4" color="primary" gutterBottom>
                        Authors
                    </Typography>
                </Grid>

                <MultiPermissionAuthorize ops={["CREATE AUTHOR"]}>
                    <Grid>
                        <AddView
                            label="add author"
                            triggerRowsRefetch={() => setChanges(changes + 1)}
                        >
                            <AddAuthor />
                        </AddView>
                    </Grid>
                </MultiPermissionAuthorize>
            </Grid>

            <BaseAccordian>
                <Grid item container spacing={2}>
                    <Grid item xs={12} md={4}>
                        {" "}
                        <Search
                            filter={filter}
                            setFilter={setFilter}
                            clear={clearFilters}
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Button
                            color="primary"
                            fullWidth
                            variant="contained"
                            onClick={() =>
                                setFilter({
                                    ...filter,
                                    apply: filter.apply + 1,
                                })
                            }
                            style={{ height: "100%" }}
                            size="small"
                        >
                            {" "}
                            search
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Tooltip title="Clear Filters">
                            <IconButton
                                onClick={handleFilterClear}
                                color="primary"
                            >
                                <ClearAll />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </BaseAccordian>

            <RUDTable
                getter={(page, limit) =>
                    AuthorService.getAuthor(page, limit, {
                        search: filter.search ? filter.search : undefined,
                        direction: sort.direction ? sort.direction : undefined,
                        column: sort.column ? sort.column : undefined,
                    })
                }
                editor={AuthorService.editAuthor}
                deletor={(id: string | number) =>
                    AuthorService.deleteAuthorSoft(id)
                }
                rowsPreprocessor={(row) => ({
                    ...row,
                    deletionId: row.id,
                })}
                defaultSelected={["name"]}
                readables={{
                    id: "ID",
                    name: "Name",
                }}
                editables={[
                    {
                        name: "name",
                        type: "textfield",
                        label: "Name",
                    },
                ]}
                updatingAgents={[filter.apply, changes, sort?.direction]}
                ops={{
                    read: "READ AUTHOR",
                    edit: "UPDATE AUTHOR",
                    delete: "DELETE AUTHOR",
                }}
                storageKey="authors"
                onSort={(row) => {
                    setSort(row);
                }}
            />
        </div>
    );
};

export default Author;
