// import { Add, Edit } from "@mui/icons-material";
// import {
//     Button,
//     Dialog,
//     DialogContent,
//     DialogProps,
//     DialogTitle,
//     IconButton,
//     useTheme,
// } from "@mui/material";
// import {
//     createContext,
//     Dispatch,
//     ReactElement,
//     SetStateAction,
//     useState,
// } from "react";

// type Props = {
//     label: string;
//     children: JSX.Element;
//     updateMode?: boolean;
//     disabled?: boolean;
//     triggerRowsRefetch?: () => void;
//     maxWidth?: DialogProps["maxWidth"];
//     icon?: ReactElement<any, any>;
// };

// type Ctx = {
//     open: any;
//     setOpen: Dispatch<SetStateAction<any>>;
//     triggerRowsRefetch: any;
// };

// export const AddViewContext = createContext<Ctx>({} as Ctx);

// const AddView = ({
//     label = "add view",
//     children,
//     triggerRowsRefetch,
//     updateMode = false,
//     disabled = false,
//     maxWidth = "xs",
//     icon,
// }: Props) => {
//     const [open, setOpen] = useState(false);
//     const theme = useTheme();

//     return (
//         <AddViewContext.Provider value={{ open, setOpen, triggerRowsRefetch }}>
//             {!updateMode ? (
//                 icon ? (
//                     <IconButton
//                         onClick={() => setOpen(true)}
//                         disabled={disabled}
//                     >
//                         {icon}
//                     </IconButton>
//                 ) : (
//                     <Button
//                         onClick={() => setOpen(true)}
//                         variant="outlined"
//                         startIcon={<Add />}
//                         disabled={disabled}
//                     >
//                         {label}
//                     </Button>
//                 )
//             ) : (
//                 <IconButton
//                     onClick={() => setOpen(true)}
//                     disabled={disabled}
//                     size="small"
//                 >
//                     <Edit fontSize="small" />
//                 </IconButton>
//             )}

//             <Dialog
//                 maxWidth={maxWidth}
//                 open={open}
//                 PaperProps={{
//                     sx: { height: "100%" },
//                 }}
//                 sx={{
//                     backdropFilter: "blur(5px)",
//                 }}
//                 onClose={() => {
//                     if (triggerRowsRefetch) triggerRowsRefetch();
//                     setOpen(false);
//                 }}
//             >
//                 <DialogTitle sx={{ textTransform: "capitalize" }}>
//                     {label}
//                 </DialogTitle>

//                 <DialogContent
//                     sx={{
//                         "&::-webkit-scrollbar": {
//                             width: "6px",
//                             borderRadius: "1000px",
//                         },

//                         "&::-webkit-scrollbar-thumb": {
//                             background: theme.palette.divider,
//                             borderRadius: "2.5px",
//                         },
//                     }}
//                 >
//                     {children}
//                 </DialogContent>
//             </Dialog>
//         </AddViewContext.Provider>
//     );
// };

// export default AddView;

import { Add, Edit } from "@mui/icons-material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {
    Button,
    Dialog,
    DialogContent,
    DialogProps,
    DialogTitle,
    IconButton,
    useTheme,
} from "@mui/material";
import {
    createContext,
    Dispatch,
    ReactElement,
    SetStateAction,
    useState,
} from "react";

type Props = {
    label: string;
    children: JSX.Element;
    updateMode?: boolean;
    duplicateMode?: boolean;
    disabled?: boolean;
    triggerRowsRefetch?: () => void;
    maxWidth?: DialogProps["maxWidth"];
    icon?: ReactElement<any, any>;
};

type Ctx = {
    open: any;
    setOpen: Dispatch<SetStateAction<any>>;
    triggerRowsRefetch: any;
};

export const AddViewContext = createContext<Ctx>({} as Ctx);

const AddView = ({
    label = "add view",
    children,
    triggerRowsRefetch,
    updateMode = false,
    duplicateMode = false,
    disabled = false,
    maxWidth = "xs",
    icon,
}: Props) => {
    const [open, setOpen] = useState(false);
    const theme = useTheme();

    const getButtonContent = () => {
        if (duplicateMode) {
            return (
                <IconButton
                    onClick={() => setOpen(true)}
                    disabled={disabled}
                    size="small"
                >
                    <ContentCopyIcon />
                </IconButton>
            );
        }

        if (updateMode) {
            return (
                <IconButton
                    onClick={() => setOpen(true)}
                    disabled={disabled}
                    size="small"
                >
                    <Edit fontSize="small" />
                </IconButton>
            );
        }

        return icon ? (
            <IconButton onClick={() => setOpen(true)} disabled={disabled}>
                {icon}
            </IconButton>
        ) : (
            <Button
                onClick={() => setOpen(true)}
                variant="outlined"
                startIcon={<Add />}
                disabled={disabled}
            >
                {label}
            </Button>
        );
    };

    return (
        <AddViewContext.Provider value={{ open, setOpen, triggerRowsRefetch }}>
            {getButtonContent()}

            <Dialog
                maxWidth={maxWidth}
                open={open}
                PaperProps={{
                    sx: { height: "100%" },
                }}
                sx={{
                    backdropFilter: "blur(5px)",
                }}
                onClose={() => {
                    if (triggerRowsRefetch) triggerRowsRefetch();
                    setOpen(false);
                }}
            >
                <DialogTitle sx={{ textTransform: "capitalize" }}>
                    {label}
                </DialogTitle>

                <DialogContent
                    sx={{
                        "&::-webkit-scrollbar": {
                            width: "6px",
                            borderRadius: "1000px",
                        },

                        "&::-webkit-scrollbar-thumb": {
                            background: theme.palette.divider,
                            borderRadius: "2.5px",
                        },
                    }}
                >
                    {children}
                </DialogContent>
            </Dialog>
        </AddViewContext.Provider>
    );
};

export default AddView;
