import { Upgrade } from "@mui/icons-material";
import {
    Alert,
    Button,
    Checkbox,
    CircularProgress,
    Divider,
    Grid,
    LinearProgress,
    Autocomplete as MuiAutoComplete,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
    useTheme,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
    APIRequestStatus,
    useGetAPI,
    usePostAPI,
} from "../../../services/Hooks";
import { SessionService } from "../../../services/SessionService";
import { StudentService } from "../../../services/StudentService";
import { AppContext } from "../../AppContext";
import { Autocomplete } from "../../AutoComplete";
import { baseAPI } from "../../constants";
import FeedbackSnackbar from "../../TestResult/FeedBackSnackBar";

const studentCols = [
    "Sr No.",
    "Reg no.",
    "Enroll no.",
    "File no.",
    "Name",
    "Father name",
    "Classroom",
];

const columnOptions = [
    { label: "Reg No.", value: "admissionNumber" },
    { label: "Enroll No.", value: "enrollmentNo" },
    { label: "File No.", value: "fileNo" },
    { label: "Name", value: "name" },
    { label: "Father Name", value: "fatherName" },
];

const orderOptions = [
    { label: "Ascending", value: "ASC" },
    { label: "Descending", value: "DESC" },
];

export const AssignedStudentCategory = () => {
    const history = useHistory();
    const theme = useTheme();
    const [lastSession, setLastSession] = useState<any>();
    const { user, feedback, setFeedback } = useContext(AppContext);
    const [selectedColumn, setSelectedColumn] = useState("admissionNumber");
    const [selectedOrder, setSelectedOrder] = useState("ASC");
    const [studentCategory, setStudentCategory] = useState<any>({
        categorieas: [],
    });

    const [pagination, setPagination] = useState({
        page: 0,
        limit: 0,
        count: 0,
    });

    const [selected, setSelected] = useState<any>({
        campus:
            user?.baseUser?.userRegions?.length > 1
                ? ""
                : user?.baseUser?.userRegions[0]?.campus?.id,
        fromSession: "",
        fromClassroom: "",
        fromSection: "",
        toCampus: "",
        toSession: "",
        toClassroom: "",
        toSection: "",
        exclude: [],
    });

    const [
        assignedCategory,
        assignedCategoryResponse,
        assignedCategoryStatus,
        assignCategoryMessage,
    ] = usePostAPI(baseAPI + "/org/student/assigned-category/bulk");

    const handleAssignedCategory = async () => {
        if (!selected.exclude || selected.exclude.length == 0) {
            setFeedback({
                loading: false,
                message:
                    "You must checked a row in order to assigned student a category",
                show: true,
                severity: "error",
            });
            return;
        }

        console.log(studentCategory.categorieas);
        assignedCategory(
            {
                students: selected.exclude,
                categorieas: studentCategory.categorieas,
            },
            {}
        );
    };

    const [students, studentCount, studentStatus, studentMessage] = useGetAPI(
        StudentService.url,
        pagination.page + 1,
        pagination.limit,
        {
            classroom: selected.fromClassroom,
            section: selected.fromSection,
            column: selectedColumn,
            direction: selectedOrder,
        },
        [
            selected.fromClassroom,
            selected.fromSection,
            selectedOrder,
            selectedColumn,
        ],
        [selected.fromClassroom, selected.fromSection]
    );

    const handleCheckChange = (type: "single" | "all", id?: number) => {
        if (type === "single") {
            if (selected.exclude.includes(id)) {
                setSelected((s: any) => ({
                    ...s,
                    exclude: selected.exclude.filter(
                        (_id: number) => _id !== id
                    ),
                }));
            } else {
                setSelected((s: any) => ({
                    ...s,
                    exclude: [...s.exclude, id],
                }));
            }
        } else {
            if (selected.exclude.length === 0) {
                setSelected((s: any) => ({
                    ...s,
                    exclude: students.map((std: any) => std.id),
                }));
            } else {
                setSelected((s: any) => ({ ...s, exclude: [] }));
            }
        }
    };

    useEffect(() => {
        setPagination((_p) => ({ ..._p, count: studentCount }));
    }, [studentCount]);

    useEffect(() => {
        if (["idle", "loading"].includes(assignedCategoryStatus)) return;

        setFeedback({
            ...feedback,
            respneseDetail:
                assignedCategoryResponse && assignedCategoryResponse,
            show: true,
            severity: assignedCategoryStatus == "success" ? "success" : "error",
            message: assignCategoryMessage,
        });

        if (assignedCategoryStatus == "success" && assignCategoryMessage) {
            history.replace("/student");
        }
    }, [
        assignedCategoryStatus,
        history,
        assignCategoryMessage,
        assignedCategoryResponse,
    ]);

    const LastSessionFromCampus = async () => {
        try {
            const [data, err] = await SessionService.getLastSession(1, 1, {
                campus: selected.campus,
            });

            if (err) {
                console.error("Error fetching last session:", err);
                return;
            }

            if (data && data.rows.length) {
                setSelected((prevFilter: any) => ({
                    ...prevFilter,
                    fromSession: data.rows[0].id,
                }));
                setLastSession(data.rows[0]);
            } else {
                setSelected((prevFilter: any) => ({
                    ...prevFilter,
                    session: "",
                }));
                setLastSession(null);
            }
        } catch (error) {
            console.error("Unexpected error in LastSession:", error);
        }
    };

    useEffect(() => {
        if (selected.campus) {
            LastSessionFromCampus();
        }
    }, [selected.campus]);

    return (
        <Grid container spacing={1}>
            {user?.baseUser?.userRegions?.length > 1 && (
                <Grid item xs={12} md={4}>
                    <Autocomplete
                        api="/org/campus"
                        setOutput={(c) =>
                            setSelected({
                                ...selected,
                                campus: c?.id || "",
                            })
                        }
                        label="Campus"
                        labelKey="name"
                        textFieldProps={{ variant: "outlined", size: "small" }}
                    />
                </Grid>
            )}

            <Grid item xs={12} md={4}>
                <Autocomplete
                    api="/org/session"
                    setOutput={(c) =>
                        setSelected({
                            ...selected,
                            fromSession: c?.id || "",
                        })
                    }
                    label="Session"
                    labelKey="name"
                    textFieldProps={{ variant: "outlined", size: "small" }}
                    apiParams={{ campus: selected.campus, status: "active" }}
                    defaultValue={lastSession}
                    key={lastSession?.id}
                />
            </Grid>

            <Grid item xs={12} md={4}>
                <Autocomplete
                    api="/org/academics/classroom"
                    setOutput={(c) =>
                        setSelected({
                            ...selected,
                            fromClassroom: c?.id || "",
                        })
                    }
                    label="Class"
                    labelKey="name"
                    textFieldProps={{ variant: "outlined", size: "small" }}
                    apiParams={{ campus: selected.campus, status: "active" }}
                />
            </Grid>

            <Grid item xs={12} md={4}>
                <Autocomplete
                    api="/org/academics/section"
                    setOutput={(c) =>
                        setSelected({
                            ...selected,
                            fromSection: c?.id || "",
                        })
                    }
                    label="Section"
                    labelKey="name"
                    textFieldProps={{ variant: "outlined", size: "small" }}
                    apiParams={{
                        classroom: selected.fromClassroom,
                        status: "active",
                    }}
                />
            </Grid>

            <Grid item xs={12} md={4}>
                <MuiAutoComplete
                    options={columnOptions}
                    getOptionLabel={(option) => option.label}
                    onChange={(event, newValue) =>
                        setSelectedColumn(newValue?.value || "")
                    }
                    value={columnOptions.find(
                        (option) => option.value === selectedColumn
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Sort By"
                            variant="outlined"
                        />
                    )}
                    size="small"
                    fullWidth
                />
            </Grid>

            <Grid item xs={12} md={4}>
                <MuiAutoComplete
                    options={orderOptions}
                    getOptionLabel={(option) => option.label}
                    onChange={(event, newValue) =>
                        setSelectedOrder(newValue?.value || "")
                    }
                    value={orderOptions.find(
                        (option) => option.value === selectedOrder
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Order"
                            variant="outlined"
                        />
                    )}
                    size="small"
                    fullWidth
                />
            </Grid>

            <Grid item xs={12}>
                <Divider>
                    <Typography
                        variant="subtitle1"
                        style={{
                            fontFamily: "monospace",
                            color: theme.palette.primary.main,
                        }}
                    >
                        SELECT STUDENT TO ASSIGNED A CATEGORY
                    </Typography>{" "}
                </Divider>
            </Grid>

            {selected.campus == "" ||
            selected.fromSession == "" ||
            selected.fromClassroom == "" ||
            selected.fromSection == "" ? (
                <Alert severity="info" style={{ width: "100%" }}>
                    Please Select a campus,session,classroom and section
                </Alert>
            ) : (
                <>
                    <Grid item xs={4}>
                        <Autocomplete
                            api="/org/student/category"
                            label="Student Category"
                            labelKey="name"
                            textFieldProps={{ size: "small" }}
                            multiple
                            setOutput={(item) =>
                                setStudentCategory({
                                    ...studentCategory,
                                    categorieas: item,
                                })
                            }
                            apiParams={{ status: "active" }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table size="small">
                                <TableHead>
                                    <TableRow
                                        sx={{
                                            "&>*": { bgcolor: "action.hover" },
                                        }}
                                    >
                                        {studentStatus ===
                                            APIRequestStatus.loading && (
                                            <TableCell colSpan={6}>
                                                <LinearProgress />
                                            </TableCell>
                                        )}
                                        {studentStatus ===
                                            APIRequestStatus.error && (
                                            <TableCell colSpan={6}>
                                                <Alert severity="error">
                                                    {studentMessage}
                                                </Alert>
                                            </TableCell>
                                        )}
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    selected.exclude.length ===
                                                    students.length
                                                }
                                                onChange={() =>
                                                    handleCheckChange("all")
                                                }
                                                indeterminate={
                                                    selected.exclude.length >
                                                        0 &&
                                                    selected.exclude.length <
                                                        studentCount
                                                }
                                            />
                                        </TableCell>
                                        {studentCols.map((col) => (
                                            <TableCell key={col}>
                                                {col}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {studentStatus ===
                                        APIRequestStatus.success &&
                                        studentCount === 0 && (
                                            <TableRow>
                                                <TableCell colSpan={12}>
                                                    <Alert severity="info">
                                                        No students in classroom
                                                    </Alert>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    {studentStatus ===
                                        APIRequestStatus.success &&
                                        students.map(
                                            (student: any, idx: number) => (
                                                <TableRow key={student.id}>
                                                    <TableCell>
                                                        <Checkbox
                                                            checked={selected.exclude.includes(
                                                                student.id
                                                            )}
                                                            onChange={() =>
                                                                handleCheckChange(
                                                                    "single",
                                                                    student.id
                                                                )
                                                            }
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        {idx + 1}
                                                    </TableCell>

                                                    <TableCell>
                                                        {
                                                            student.admissionNumber
                                                        }
                                                    </TableCell>

                                                    <TableCell>
                                                        {student.enrollmentNo}
                                                    </TableCell>
                                                    <TableCell>
                                                        {student.fileNo}
                                                    </TableCell>

                                                    <TableCell>
                                                        {student.baseUser.name}
                                                    </TableCell>
                                                    <TableCell>
                                                        {student.fatherName}
                                                    </TableCell>
                                                    <TableCell>{`${student.classroom.name} (${student.section.name}), ${student.session.name}`}</TableCell>
                                                </TableRow>
                                            )
                                        )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="outlined"
                            fullWidth
                            type={"submit"}
                            onClick={handleAssignedCategory}
                            sx={{ height: "100%" }}
                            disabled={
                                assignedCategoryStatus ===
                                APIRequestStatus.loading
                            }
                            endIcon={
                                assignedCategoryStatus ===
                                APIRequestStatus.loading ? (
                                    <CircularProgress size="1em" />
                                ) : (
                                    <Upgrade />
                                )
                            }
                        >
                            {assignedCategoryStatus === APIRequestStatus.loading
                                ? "Assigning  Category..."
                                : "Assign  Category"}
                        </Button>
                    </Grid>
                </>
            )}

            <FeedbackSnackbar feedback={feedback} setFeedback={setFeedback} />
        </Grid>
    );
};
