import { ClearAll } from "@mui/icons-material";
import {
    Button,
    Chip,
    Grid,
    IconButton,
    TextField,
    Tooltip,
    Typography,
    useTheme,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { MultiPermissionAuthorize } from "../../../MultiPermissionAuthorize";
import { SessionService } from "../../../services/SessionService";
import { StudentAttendanceService } from "../../../services/StudentAttendanceService";
import { AppContext } from "../../AppContext";
import { Autocomplete } from "../../AutoComplete";
import { Search } from "../../Filters/Search";
import { RUDTable } from "../../Table/RUDTable";
import FeedbackSnackbar from "../../TestResult/FeedBackSnackBar";
import { dateFormatter } from "../../constants";
import EditSingleAttendance from "./EditSingleAttendance";

const StudentAttendance = () => {
    const [showWithDelay, setShowWithDelay] = useState(false);
    const { user, feedback, setFeedback } = useContext(AppContext);
    const theme = useTheme();
    const [lastSession, setLastSession] = useState<any>();
    const [sort, setSort] = useState<{ column: ""; direction: "" }>();
    const [filter, setFilter] = useState<any>({
        search: "",
        attendanceDate: "",
        subject: "",
        user: user?.baseUser?.id,
        campus:
            user?.baseUser?.userRegions?.length > 1
                ? ""
                : user?.baseUser?.userRegions[0]?.campus?.id,
        classroom: "",
        type: "",
        session: "",
        section: "",
        startDate: new Date().toISOString().split("T")[0],
        endDate: new Date().toISOString().split("T")[0],
        attendanceStatus: "",
        apply: 0,
    });
    const [clearFilters, setClearFilters] = useState(false);

    const handleFilterClear = () => {
        setClearFilters(!clearFilters);
        setFilter({
            search: "",
            attendanceDate: "",
            subject: "",
            user: user?.baseUser?.id,
            campus:
                user?.baseUser?.userRegions?.length > 1
                    ? ""
                    : user?.baseUser?.userRegions[0]?.campus?.id,
            classroom: "",
            type: "",
            session: "",
            section: "",
            attendanceStatus: "",
            apply: 0,
        });
    };

    const LastSession = async () => {
        try {
            const [data, err] = await SessionService.getLastSession(1, 1, {
                campus: filter.campus ? filter.campus : null,
            });

            if (err) {
                console.error("Error fetching last session:", err);
                return;
            }

            if (data && data.rows.length) {
                setFilter((prevData: any) => ({
                    ...prevData,
                    session: data.rows[0].id,
                }));
                setLastSession(data.rows[0]);
            } else {
                console.warn("No session data found.");
            }
        } catch (error) {
            console.error("Unexpected error in LastSession:", error);
        }
    };

    useEffect(() => {
        if (filter.campus) {
            LastSession();
        }
    }, [filter?.campus as any]);

    const [changes, setChanges] = useState(0);

    useEffect(() => {
        let timer: any;
        if (feedback.show) {
            // Start the delay timer
            timer = setTimeout(() => {
                setShowWithDelay(true);
            }, 500); // Adjust the delay time in milliseconds
        } else {
            // Reset visibility when feedback.show is false
            setShowWithDelay(false);
        }

        return () => clearTimeout(timer); // Clean up the timer
    }, [feedback.show]);
    return (
        <div>
            {/* <BaseAccordian > */}
            <Grid container spacing={1.5}>
                {user?.baseUser?.userRegions?.length > 1 && (
                    <Grid item xs={12} md={4}>
                        <Autocomplete
                            api="/org/campus"
                            setOutput={(c: any) =>
                                setFilter({
                                    ...filter,
                                    campus: c?.id || "",
                                })
                            }
                            label="Campus"
                            labelKey="name"
                            textFieldProps={{
                                variant: "outlined",
                                size: "small",
                            }}
                            clear={clearFilters}
                        />
                    </Grid>
                )}

                <Grid item xs={12} md={4}>
                    <Autocomplete
                        api="/org/session"
                        setOutput={(c: any) =>
                            setFilter({
                                ...filter,
                                session: c.id || "",
                            })
                        }
                        label="Session"
                        labelKey="name"
                        textFieldProps={{
                            variant: "outlined",
                            size: "small",
                            required: true,
                        }}
                        clear={clearFilters}
                        apiParams={{
                            campus: filter.campus,
                            status: "active",
                        }}
                        defaultValue={lastSession}
                        key={lastSession?.id}
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <Autocomplete
                        api="/org/academics/classroom"
                        setOutput={(c: any) =>
                            setFilter({
                                ...filter,
                                classroom: c?.id || "",
                            })
                        }
                        label="Class"
                        labelKey="name"
                        textFieldProps={{
                            variant: "outlined",
                            size: "small",
                            required: true,
                        }}
                        clear={clearFilters}
                        apiParams={{
                            campus: filter.campus,
                            status: "active",
                        }}
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <Autocomplete
                        api="/org/academics/section"
                        setOutput={(c: any) =>
                            setFilter({
                                ...filter,
                                section: c.id || "",
                            })
                        }
                        label="Section"
                        labelKey="name"
                        textFieldProps={{
                            variant: "outlined",
                            size: "small",
                            required: true,
                        }}
                        clear={clearFilters}
                        apiParams={{
                            classroom: filter.classroom,
                            status: "active",
                        }}
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <Autocomplete
                        defaultOptions={[
                            {
                                label: "Attendance By Day",
                                status: "dayWise",
                                id: "dayWise",
                            },
                            {
                                label: "Attendance By Subject",
                                status: "subject",
                                id: "subject",
                            },
                        ]}
                        setOutput={(c: any) =>
                            setFilter({
                                ...filter,
                                type: c?.id || "",
                            })
                        }
                        label="Attendance Type"
                        labelKey="label"
                        textFieldProps={{
                            variant: "outlined",
                            size: "small",
                            required: true,
                        }}
                        clear={clearFilters}
                    />
                </Grid>

                {filter?.type == "subject" && (
                    <Grid item xs={12} md={4}>
                        <Autocomplete
                            api="/org/Academics/Subject"
                            setOutput={(c: any) =>
                                setFilter({
                                    ...filter,
                                    subject: c?.id || "",
                                })
                            }
                            label="Subject"
                            labelKey="name"
                            textFieldProps={{
                                variant: "outlined",
                                size: "small",
                            }}
                            clear={clearFilters}
                            apiParams={{
                                classroom: filter.classroom,
                                status: "active",
                            }}
                        />
                    </Grid>
                )}

                <Grid item xs={12} md={4}>
                    <Autocomplete
                        defaultOptions={[
                            {
                                label: "Present",
                                status: "present",
                                id: "Present",
                            },
                            {
                                label: "Absent",
                                status: "absent",
                                id: "Absent",
                            },

                            {
                                label: "Leave",
                                status: "leave",
                                id: "Leave",
                            },

                            {
                                label: "Late",
                                status: "late",
                                id: "Late",
                            },
                            {
                                label: "Sick leave",
                                status: "sickLeave",
                                id: "sickLeave",
                            },
                            {
                                label: "Off day",
                                status: "offDay",
                                id: "offDay",
                            },
                        ]}
                        setOutput={(des: any) => {
                            setFilter({
                                ...filter,
                                attendanceStatus: des.status,
                            });
                        }}
                        labelKey="label"
                        label="Attendance Status"
                        textFieldProps={{
                            variant: "outlined",
                            size: "small",
                        }}
                        clear={clearFilters}
                    />
                </Grid>

                <Grid item xs={3} md={4}>
                    <TextField
                        type="date"
                        size="small"
                        fullWidth
                        label="From Date"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) =>
                            setFilter({
                                ...filter,
                                startDate: e.target.value,
                            })
                        }
                        defaultValue={new Date().toISOString().split("T")[0]}
                    />
                </Grid>

                <Grid item xs={3} md={4}>
                    <TextField
                        type="date"
                        size="small"
                        fullWidth
                        label="To Date"
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) =>
                            setFilter({
                                ...filter,
                                endDate: e.target.value,
                            })
                        }
                        variant="outlined"
                        defaultValue={new Date().toISOString().split("T")[0]}
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <Search
                        filter={filter}
                        setFilter={setFilter}
                        clear={clearFilters}
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <Button
                        color="primary"
                        fullWidth
                        variant="contained"
                        onClick={() =>
                            setFilter({
                                ...filter,
                                apply: filter.apply + 1,
                            })
                        }
                        style={{ height: "100%" }}
                        size="small"
                    >
                        {" "}
                        Search
                    </Button>
                </Grid>

                <Grid item xs={12} md={6} lg={3}>
                    <Tooltip title="Clear Filters">
                        <IconButton onClick={handleFilterClear} color="primary">
                            <ClearAll />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
            {/* </BaseAccordian> */}
            <RUDTable
                getter={(page, limit) =>
                    StudentAttendanceService.getAttendance(page, limit, {
                        search: filter.search,
                        attendanceDate: filter.attendanceDate,
                        subject: filter.subject,
                        user: filter.user,
                        campus: filter.campus,
                        classroom: filter.classroom,
                        type: filter.type,
                        session: filter.session,
                        section: filter.section,
                        startDate: filter.startDate,
                        endDate: filter.endDate,
                        attendanceStatus: filter.attendanceStatus,
                        column: sort?.column ? sort.column : undefined,
                        direction: sort?.direction ? sort.direction : undefined,
                    })
                }
                rowsPreprocessor={(row) => ({
                    id: row.id,
                    deletionId: row.id,
                    name: row.student.baseUser.name,
                    fatherName: row.student.fatherName,
                    fileNo: row.student.fileNo,
                    enrollmentNo: row.student.enrollmentNo,
                    admissionNo: row.student.admissionNumber,
                    campusName: row.campus.name,
                    classroomName: row.classroom.name,
                    attendanceDate: dateFormatter.format(
                        new Date(row.attendanceDate)
                    ),
                    attendanceStatus:
                        row.attendanceStatus == "present" ? (
                            <Chip
                                label={"Present"}
                                size="small"
                                color="success"
                                style={{ width: "100%" }}
                            />
                        ) : row.attendanceStatus == "absent" ? (
                            <Chip
                                label={"Absent"}
                                size="small"
                                color="error"
                                style={{ width: "100%" }}
                            />
                        ) : row.attendanceStatus == "leave" ? (
                            <Chip
                                label={"Leave"}
                                size="small"
                                color="primary"
                                style={{ width: "100%" }}
                            />
                        ) : row.attendanceStatus == "pending" ? (
                            <Chip
                                label={"Pending"}
                                size="small"
                                color="info"
                                style={{ width: "100%" }}
                            />
                        ) : row.attendanceStatus == "offDay" ? (
                            <Chip
                                label={"Off Day"}
                                size="small"
                                // color=""
                                style={{ backgroundColor: "#29b6f6" }}
                            />
                        ) : row.attendanceStatus == "sickLeave" ? (
                            <Chip
                                label={"Sick Leave"}
                                size="small"
                                style={{
                                    width: "100%",
                                    backgroundColor: "#ce93d8",
                                }}
                                color="default"
                            />
                        ) : (
                            <Chip
                                label={"Late"}
                                size="small"
                                color="warning"
                                style={{ width: "100%" }}
                            />
                        ),

                    lateDate: row.lateDate ? (
                        <Typography>
                            {dateFormatter.format(new Date(row.lateDate))}
                        </Typography>
                    ) : (
                        <Typography style={{ color: theme.palette.divider }}>
                            N/A
                        </Typography>
                    ),
                    operator: row.user.name,
                    _attendanceStatus: row.attendanceStatus,
                    _lateDate: row.lateDate ? row.lateDate : null,
                })}
                readables={{
                    admissionNo: "Reg No.",
                    enrollmentNo: "Enroll No",
                    fileNo: "File No",
                    name: "Name",
                    fatherName: "FatherName",
                    campusName: "Campus",
                    classroomName: "Class",
                    attendanceDate: "Attendance Date",
                    attendanceStatus: "Status",
                    operator: "Operator",
                    lateDate: "Late Date",
                    Edit: "Edit",

                    // Edit: "Edit",
                    // Changesequence: "Change Sequence",
                    // status: "Status",
                }}
                editables={[]}
                customCols={[
                    {
                        header: user?.baseUser?.ops.includes(
                            "UPDATE ATTENDANCE"
                        )
                            ? "Edit"
                            : "",
                        content: (row) => (
                            <MultiPermissionAuthorize
                                ops={["UPDATE ATTENDANCE"]}
                            >
                                <EditSingleAttendance
                                    row={row}
                                    changes={changes}
                                    setChanges={setChanges}
                                />
                            </MultiPermissionAuthorize>
                        ),
                    },
                ]}
                updatingAgents={[filter.apply, changes, sort?.direction]}
                ops={{
                    read: "READ ATTENDANCE",
                    edit: "EDIT ATTENDANCE",
                    delete: "DELETE ATTENDANCE",
                }}
                storageKey="student Attendance"
                onSort={(row: any) => {
                    setSort(row);
                }}
                unsortableCols={["Edit"]}
            />

            {showWithDelay && (
                <FeedbackSnackbar
                    feedback={feedback}
                    setFeedback={setFeedback}
                />
            )}
        </div>
    );
};

export default StudentAttendance;
