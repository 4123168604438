import { CancelOutlined, CheckCircleOutlined } from "@mui/icons-material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    TextField,
    useTheme,
} from "@mui/material";
import { useContext, useState } from "react";
import { StudentAttendanceService } from "../../../services/StudentAttendanceService";
import { AppContext } from "../../AppContext";

const StudentLeaveAction = ({ row, changes, setChanges }: any) => {
    const { feedback, setFeedback } = useContext(AppContext);
    const [open, setOpen] = useState(false);
    const [remarks, setRemarks] = useState<any>();
    const theme = useTheme();

    // Handler for the Accept action
    const handleAccept = async () => {
        const [data, err] =
            await StudentAttendanceService.editSingleStudentAttendance(row.id, {
                leaveStatus: "accepted",
                attendanceStatus: "leave",
                remarks: remarks,
            });

        if (data) {
            setFeedback({
                loading: false,
                message: "Student Application accepted successfully",
                severity: "success",
                show: true,
            });

            const timer = setTimeout(() => {
                setOpen(false);
                setChanges(changes + 1);
                setFeedback({ ...feedback, message: "", show: false });
            }, 3000);
        } else {
            setFeedback({
                loading: false,
                message: "failed to accept student application",
                severity: "error",
                show: true,
            });
        }
    };

    // Handler for the Reject action
    const handleReject = async () => {
        const [data, err] =
            await StudentAttendanceService.editSingleStudentAttendance(row.id, {
                leaveStatus: "rejected",
                attendanceStatus: "absent",
                remarks: remarks,
            });

        if (data) {
            setFeedback({
                loading: false,
                message: "Student Application rejected successfully",
                severity: "success",
                show: true,
            });
            const timer = setTimeout(() => {
                setOpen(false);
                setChanges(changes + 1);
                setFeedback({ ...feedback, message: "", show: false });
            }, 3000);
        } else {
            setFeedback({
                loading: false,
                message: "failed to reject student application",
                severity: "error",
                show: true,
            });
        }
    };

    return (
        <div>
            <IconButton
                onClick={() => {
                    setOpen(true);
                }}
                size="small"
            >
                <AddCircleIcon fontSize="small" />
            </IconButton>

            <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
                {/* <Grid item xs={12} style={{ padding: "1rem" }}>
                    {feedback.show && (
                        <Alert severity={feedback.severity}>
                            {feedback.message}
                        </Alert>
                    )}
                </Grid> */}
                <DialogTitle>Leave Application Decision</DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        Please review the student's leave application. Click
                        "Accept" to approve the application, or "Reject" to
                        decline it.
                    </DialogContentText>
                </DialogContent>

                <div style={{ width: "100%", padding: "1rem" }}>
                    <TextField
                        size="small"
                        label="Remarks"
                        fullWidth
                        value={remarks}
                        name="name"
                        onChange={(ev) => {
                            setRemarks(ev.target.value);
                        }}
                        rows={4}
                        multiline
                        required
                    />
                </div>
                <DialogActions>
                    <Button
                        onClick={handleAccept}
                        variant="contained"
                        color="success"
                        startIcon={<CheckCircleOutlined />}
                    >
                        Accept
                    </Button>

                    <Button
                        onClick={handleReject}
                        variant="contained"
                        color="error"
                        startIcon={<CancelOutlined />}
                    >
                        Reject
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default StudentLeaveAction;
