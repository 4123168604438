import { Box, Button, Grid, IconButton } from "@mui/material";
import { CSSProperties, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { CertificateService } from "../../services/CertificateService";

import { Edit } from "@mui/icons-material";
import {
    MultiPermissionAuthorize,
    useMultipermissionAuthorize,
} from "../../MultiPermissionAuthorize";
import { AppContext } from "../AppContext";
import { Search } from "../Filters/Search";
import { RUDTable } from "../Table/RUDTable";
import { CreationViewBtn } from "./CreationViewBtn";
import PreviewCertificate from "./History/PreviewCertificate";

export type Footer = {
    id: number | string;
    label: string;
    checked: boolean;
} & ({ type: "image"; image: File; url: string } | { type: "text" });

export type CertificateComponents = {
    header: {
        logo: boolean;
        profilePicture: boolean;
        position: CSSProperties["justifyContent"];
        baseUser: any;
    };

    title: {
        text: string;
        placeholders: string[];
        color: string;
        fontSize: string | number;
    };

    subTitle: {
        text: string;
        placeholders: string[];
        color: string;
        fontSize: string | number;
    };

    userName: {
        text: string;
        placeholders: string[];
        color: string;
        fontSize: string | number;
    };

    body: {
        text: string;
        placeholders: string[];
        color: string;
        fontSize: string | number;
    };

    additionalBody?: {
        text: string;
        placeholders: string[];
        color: string;
        fontSize: string | number;
    };

    footer?: { fields: Footer[]; position: CSSProperties["justifyContent"] };
};

export type Certificate = {
    id?: number;
    type: string;
    name: string;
    fields: CertificateComponents;
    thumbnail?: string;
};

const Certificates = () => {
    const { user } = useContext(AppContext);
    const [filter, setFilter] = useState({ search: "", apply: 0 });
    const [sort, setSort] = useState<{ column: ""; direction: "" }>();
    const userCanUpdate = useMultipermissionAuthorize(["EDIT CERTIFICATE"]);

    const history = useHistory();

    return (
        <Box>
            <MultiPermissionAuthorize ops={["CREATE CERTIFICATE"]}>
                <CreationViewBtn
                    creationViewLink="/user/certificate/create"
                    buttonLabel="Create Certificate"
                />
            </MultiPermissionAuthorize>

            <Grid container sx={{ pt: "1rem" }} spacing={"2"}>
                <Grid item xs={12} md={6} lg={3}>
                    <Search filter={filter} setFilter={setFilter} />
                </Grid>

                <Grid item xs={12} md={6} lg={3}>
                    <Button
                        color="primary"
                        fullWidth
                        variant="outlined"
                        onClick={() =>
                            setFilter({
                                ...filter,
                                apply: filter.apply + 1,
                            })
                        }
                        style={{ height: "100%" }}
                        size="small"
                    >
                        {" "}
                        search
                    </Button>
                </Grid>
            </Grid>

            <RUDTable
                getter={(page, limit) =>
                    CertificateService.getCertificates(page, limit, {
                        search: filter.search ? filter.search : undefined,
                        column: sort?.column ? sort.column : undefined,
                        direction: sort?.direction ? sort.direction : undefined,
                    })
                }
                deletor={(ids) =>
                    CertificateService.deleteCertificate(ids as any)
                }
                readables={{
                    id: "ID",
                    name: "Name",
                    _type: "Type",
                    Edit: "Edit",
                    PreviewTemplate: "Preview Template",
                }}
                rowsPreprocessor={(row) => ({
                    ...row,
                    deletionId: row.id,
                    name: row.name,
                    _type: (
                        <span style={{ textTransform: "capitalize" }}>
                            {row.type}
                        </span>
                    ),
                })}
                customCols={[
                    {
                        header: "PreviewTemplate",
                        content: (row) => (
                            <PreviewCertificate
                                data={{
                                    id: row.id,
                                    certificate: row,
                                    classroom: row.classroom,
                                    baseUser: row.baseUser,
                                }}
                            />
                        ),
                    },

                    {
                        header: user?.baseUser?.ops.includes(
                            "UPDATE CERTIFICATE"
                        )
                            ? "Edit"
                            : "",
                        content: (row) => (
                            <IconButton
                                onClick={() => {
                                    history.push(
                                        `/user/certificate/update/${row.id}`
                                    );
                                }}
                                size="small"
                            >
                                <Edit fontSize="small" />
                            </IconButton>
                        ),
                    },
                ]}
                ops={{
                    read: "READ CERTIFICATE",
                    delete: "DELETE CERTIFICATE",
                }}
                storageKey="certificate"
                updatingAgents={[filter.apply, sort?.direction]}
                onSort={(row: any) => {
                    setSort(row);
                }}
                unsortableCols={["PreviewTemplate", "Edit"]}
            />
        </Box>
    );
};

export default Certificates;
