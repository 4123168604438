import {
    Box,
    Grid,
    Autocomplete as MuiAutoComplete,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    useTheme,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { GET } from "../../services/BaseService";
import { LogService } from "../../services/LogServices";
import { S3Service } from "../../services/S3Service";
import { Autocomplete } from "../AutoComplete";
import { awsDirStruct, dateFormatter } from "../constants";
import { LogFilter } from "./LogsFilter";
import { SingleLog } from "./SingleLog";

const columnOptions = [
    { label: "Timestamp	", value: "createdAt" },
    { label: "Id", value: "id" },
    { label: "Operator", value: "Operator" },
    { label: "Response status", value: "response" },
];

const orderOptions = [
    { label: "Ascending", value: "ASC" },
    { label: "Descending", value: "DESC" },
];

const fetchLogsFromS3 = async (isoDate: string, operator: any) => {
    const d = new Date(isoDate);
    const key = `${
        awsDirStruct.logsInfo.logs
    }/log_${d.getFullYear()}_${d.getMonth()}_${d.getDate()}.log`;
    const [data, err] = await S3Service.getS3ByKey(key);

    if (data) {
        const response = await fetch(data.url);
        const blob = await response.blob();
        const fr = new FileReader();

        const pr = new Promise<string>((resolve, reject) => {
            fr.onload = (ev) => {
                if (ev.target) {
                    resolve(ev.target.result as string);
                }
            };

            fr.onerror = reject;
            fr.readAsText(blob);
        });

        try {
            const data = JSON.parse(await pr);
            if (operator) {
                const filtered = data.filter(
                    (row: any) => row.operator.id == operator
                );

                return {
                    rows: filtered,
                    count: filtered.length,
                };
            } else {
                return {
                    rows: data,
                    count: data.length,
                };
            }
        } catch (err) {
            console.log("Error parsing JSON", err);
            return { rows: [] };
        }
    } else {
        return { rows: [] };
    }
};

const fetchLogs = (filter: any = {}, page = 0, limit = 500): Promise<any> => {
    const filterWithoutDate = { ...filter, date: undefined };

    if (filter.date === new Date().toISOString().split("T")[0]) {
        return GET("/logs", { ...filterWithoutDate, page: page + 1, limit });
    } else {
        return fetchLogsFromS3(filter.date, filter.operator);
    }
};

export const Logs = () => {
    const [filter, setFilter] = useState({
        date: new Date().toISOString().split("T")[0],
        operator: "",
    });

    const [selectedColumn, setSelectedColumn] = useState("id");
    const [selectedOrder, setSelectedOrder] = useState("ASC");
    const [pagination, setPagination] = useState({
        page: 0,
        count: 0,
        limit: 50,
    });
    const [logs, setLogs] = useState<any>([]);
    const [printMode, setPrintMode] = useState(false);
    const exportRef = useRef<any>("");
    const theme = useTheme();

    const getLogs = async () => {
        const [data, err] = await LogService.getLog(
            pagination.page + 1,
            pagination.limit,
            {
                operator:
                    filter.operator && filter.operator !== "all"
                        ? filter.operator
                        : undefined,
                column: selectedColumn,
                direction: selectedOrder,
                date: filter.date,
            }
        );
        if (data) {
            setLogs(data.rows);
            setPagination({ ...pagination, count: data.count });
        }
    };

    useEffect(() => {
        getLogs();
    }, [
        filter,
        pagination.page,
        pagination.limit,
        selectedColumn,
        selectedOrder,
    ]);

    return (
        <Box>
            <Grid container spacing={1}>
                <Grid item xs={12} md={3}>
                    <Autocomplete
                        api="/org/user"
                        processor={(opt) => ({
                            ...opt,
                            name: opt.baseUser.name,
                        })}
                        label="Operator"
                        textFieldProps={{ size: "small" }}
                        setOutput={(user) =>
                            setFilter({ ...filter, operator: user.baseUser.id })
                        }
                        labelKey="name"
                    />
                </Grid>

                <Grid item xs={12} md={3}>
                    <LogFilter filter={filter} setFilter={setFilter} />
                </Grid>
                <Grid item xs={12} md={3}>
                    <MuiAutoComplete
                        options={columnOptions}
                        getOptionLabel={(option) => option.label}
                        onChange={(event, newValue) =>
                            setSelectedColumn(newValue?.value || "")
                        }
                        value={columnOptions.find(
                            (option) => option.value === selectedColumn
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Sort By"
                                variant="outlined"
                            />
                        )}
                        size="small"
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12} md={3}>
                    <MuiAutoComplete
                        options={orderOptions}
                        getOptionLabel={(option) => option.label}
                        onChange={(event, newValue) =>
                            setSelectedOrder(newValue?.value || "")
                        }
                        value={orderOptions.find(
                            (option) => option.value === selectedOrder
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Order"
                                variant="outlined"
                            />
                        )}
                        size="small"
                        fullWidth
                    />
                </Grid>
            </Grid>

            <Grid
                item
                xs={12}
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <div>
                    {/* <TableToExcel
                        fileName="Logs"
                        sheetName=""
                        targetRef={exportRef}
                    /> */}
                    {/* <Print
                        componentRef={exportRef}
                        setPrintMode={setPrintMode}
                    /> */}
                </div>
                <TablePagination
                    component="div"
                    onPageChange={(ev, page) =>
                        setPagination({ ...pagination, page: page })
                    }
                    onRowsPerPageChange={(ev) =>
                        setPagination({
                            ...pagination,
                            limit: parseInt(ev.target.value),
                            page: 0,
                        })
                    }
                    count={pagination.count}
                    page={pagination.page}
                    rowsPerPage={pagination.limit}
                />
            </Grid>

            <TableContainer component={Paper} elevation={0}>
                <Table size="small" ref={exportRef}>
                    <TableHead>
                        <TableRow
                            sx={{
                                "& > *": {
                                    bgcolor: "action.selected",
                                    fontWeight: "550",
                                },
                            }}
                        >
                            <TableCell>Timestamp</TableCell>
                            <TableCell>Id</TableCell>
                            <TableCell>Operator</TableCell>
                            <TableCell>Action</TableCell>
                            <TableCell>Response&nbsp;status</TableCell>
                            <TableCell>Platform</TableCell>
                            <TableCell>IP</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Boolean(logs.length) ? (
                            logs.map((log: any) => (
                                <SingleLog key={log.id} log={log} />
                            ))
                        ) : (
                            <TableRow>
                                <TableCell
                                    align="center"
                                    colSpan={6}
                                    sx={{ bgcolor: "warning.light" }}
                                >
                                    No logs dated:{" "}
                                    {dateFormatter.format(
                                        new Date(filter.date) || new Date()
                                    )}
                                </TableCell>
                            </TableRow>
                        )}

                        {/* {status === "loading" && (
                            <TableRow>
                                <TableCell align="center" colSpan={6}>
                                    <LinearProgress />
                                </TableCell>
                            </TableRow>
                        )} */}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};
