import {
    Checkbox,
    FormControl,
    TableCell,
    TableRow,
    TextField,
} from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";

export const TestResultRow = ({
    id,
    singleStudent,
    testResult,
    setTestResult,
    checkAll,
    limit,
    totalMarks,
}: any) => {
    const [isChecked, setIsChecked] = useState(checkAll);

    const [feedback, setFeedback] = useState({
        message: "",
        show: false,
        severity: "",
        loading: false,
    });
    function handleCheck(
        event: ChangeEvent<HTMLInputElement>,
        checked: boolean
    ) {
        setIsChecked(true);
        setTestResult((curr: any) => {
            const withCurrChecked = {
                ...curr,
                [singleStudent.baseUser.id]: {
                    ...testResult[singleStudent.baseUser.id],
                    checked,
                },
            };

            return withCurrChecked;
        });
    }

    // const handleChange = (ev: any, studentId: any) => {
    //     const { value } = ev.target;
    //     const obtainedMarks = parseFloat(value);

    //     if (!totalMarks) {
    //         setFeedback({
    //             show: true,
    //             message: `Must provide total marks (${totalMarks}).`,
    //             loading: false,
    //             severity: "error",
    //         });
    //         return;
    //     }

    //     if (isNaN(obtainedMarks)) {
    //         setFeedback({
    //             show: true,
    //             message: "Obtained marks must be a valid number.",
    //             loading: false,
    //             severity: "error",
    //         });
    //         return;
    //     }

    //     if (obtainedMarks > totalMarks) {
    //         setFeedback({
    //             show: true,
    //             message: `Obtained marks cannot exceed total marks (${totalMarks}).`,
    //             loading: false,
    //             severity: "error",
    //         });
    //         return;
    //     }

    //     setFeedback({
    //         show: false,
    //         message: "",
    //         loading: false,
    //         severity: "error",
    //     });

    //     setTestResult((prev: any) => ({
    //         ...prev,
    //         [studentId]: {
    //             ...prev[studentId],
    //             marks: obtainedMarks,
    //             checked: true,
    //         },
    //     }));
    // };

    const handleChange = (ev: any, studentId: any) => {
        const { value } = ev.target;
        const obtainedMarks = parseFloat(value);
        const maxMarks = parseFloat(totalMarks);

        console.log(value);

        if (!maxMarks) {
            setFeedback({
                show: true,
                message: `Must provide total marks (${totalMarks}).`,
                loading: false,
                severity: "error",
            });
            return;
        }

        if (isNaN(obtainedMarks)) {
            setFeedback({
                show: true,
                message: "Obtained marks must be a valid number.",
                loading: false,
                severity: "error",
            });
            return;
        }

        console.log(obtainedMarks, maxMarks);
        if (obtainedMarks > maxMarks) {
            setFeedback({
                show: true,
                message: `Obtained marks cannot exceed total marks (${maxMarks}).`,
                loading: false,
                severity: "error",
            });
            return;
        }

        setFeedback({
            show: false,
            message: "",
            loading: false,
            severity: "error",
        });

        setTestResult((prev: any) => ({
            ...prev,
            [studentId]: {
                ...prev[studentId],
                marks: obtainedMarks,
                checked: true,
            },
        }));
    };

    useEffect(() => {
        setIsChecked(testResult[singleStudent?.baseUser?.id]?.checked ?? false);
    }, [testResult[singleStudent?.baseUser?.id]?.checked]);

    return (
        <TableRow>
            <TableCell>
                <FormControl>
                    <Checkbox checked={isChecked} onChange={handleCheck} />
                </FormControl>
            </TableCell>

            <TableCell>{id}</TableCell>
            <TableCell>{singleStudent.admissionNumber}</TableCell>
            <TableCell>{singleStudent.enrollmentNo}</TableCell>
            <TableCell>{singleStudent.fileNo}</TableCell>
            <TableCell>{singleStudent.id}</TableCell>
            <TableCell style={{ whiteSpace: "nowrap" }}>
                {singleStudent.baseUser.name}
            </TableCell>
            <TableCell style={{ whiteSpace: "nowrap" }}>
                {singleStudent.fatherName}
            </TableCell>

            <TableCell>
                <TextField
                    style={{ minWidth: "220px" }}
                    size="small"
                    name="marks"
                    label="Obtained marks"
                    value={testResult[singleStudent.baseUser.id]?.marks}
                    onChange={(ev) =>
                        handleChange(ev, singleStudent.baseUser.id)
                    }
                    variant="outlined"
                    key={singleStudent.baseUser.id}
                    type="number"
                    inputProps={{ step: 0.1 }}
                    error={feedback.show}
                    helperText={feedback.show && feedback.message}
                />
            </TableCell>
        </TableRow>
    );
};
