import { ClearAll } from "@mui/icons-material";
import {
    Box,
    Button,
    Grid,
    IconButton,
    Tooltip,
    Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import BaseAccordian from "../../../BaseAccordian";
import { SessionService } from "../../../services/SessionService";
import { StudentAttendanceService } from "../../../services/StudentAttendanceService";
import { AppContext } from "../../AppContext";
import { Autocomplete } from "../../AutoComplete";
import { RUDTable } from "../../Table/RUDTable";

const OverAllAttendance = () => {
    const { user } = useContext(AppContext);
    const [totalAttendanceTaken, setTotalAttendanceTaken] = useState<any>();
    const [lastSession, setLastSession] = useState<any>();
    const [sort, setSort] = useState<{ direction: ""; column: "" }>();

    const [filter, setFilter] = useState<any>({
        search: "",
        campus:
            user?.baseUser?.userRegions?.length > 1
                ? ""
                : user?.baseUser?.userRegions[0]?.campus?.id,
        status: "",
        student: "",
        apply: 0,
    });
    const [clearFilters, setClearFilters] = useState(false);

    const handleFilterClear = () => {
        setClearFilters(!clearFilters);
        setFilter({
            search: "",
            campus: "",
            status: "",
            student: "",
            apply: 0,
        });
    };

    const [changes, setChanges] = useState(0);

    const LastSession = async () => {
        try {
            const [data, err] = await SessionService.getLastSession(1, 1, {
                campus: filter.campus ? filter.campus : null,
            });

            if (err) {
                console.error("Error fetching last session:", err);
                return;
            }

            if (data && data.rows.length) {
                setFilter((prevData: any) => ({
                    ...prevData,
                    session: data.rows[0].id,
                }));
                setLastSession(data.rows[0]);
            } else {
                console.warn("No session data found.");
            }
        } catch (error) {
            console.error("Unexpected error in LastSession:", error);
        }
    };

    useEffect(() => {
        if (filter.campus) {
            LastSession();
        }
    }, [filter?.campus as any]);

    return (
        <div>
            <BaseAccordian>
                <Grid container spacing={1.5}>
                    {user?.baseUser?.userRegions?.length > 1 && (
                        <Grid item xs={12} md={4}>
                            <Autocomplete
                                api="/org/campus"
                                setOutput={(c: any) =>
                                    setFilter({
                                        ...filter,
                                        campus: c?.id || "",
                                    })
                                }
                                label="Campus"
                                labelKey="name"
                                textFieldProps={{
                                    variant: "outlined",
                                    size: "small",
                                }}
                                clear={clearFilters}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12} md={4}>
                        <Autocomplete
                            api="/org/session"
                            setOutput={(c: any) =>
                                setFilter({
                                    ...filter,
                                    session: c.id || "",
                                })
                            }
                            label="Session"
                            labelKey="name"
                            textFieldProps={{
                                variant: "outlined",
                                size: "small",
                                required: true,
                            }}
                            clear={clearFilters}
                            apiParams={{
                                campus: filter.campus,
                                status: "active",
                            }}
                            defaultValue={lastSession}
                            key={lastSession?.id}
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Button
                            color="primary"
                            fullWidth
                            variant="contained"
                            onClick={() =>
                                setFilter({
                                    ...filter,
                                    apply: filter.apply + 1,
                                })
                            }
                            style={{ height: "100%" }}
                            size="small"
                        >
                            {" "}
                            Search
                        </Button>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Tooltip title="Clear Filters">
                            <IconButton
                                onClick={handleFilterClear}
                                color="primary"
                            >
                                <ClearAll />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </BaseAccordian>

            <RUDTable
                getter={(page, limit) =>
                    StudentAttendanceService.getOverAllAttendance(page, limit, {
                        search: filter.search,
                        subject: filter.subject,
                        type: filter.type,
                        session: filter.session,
                        attendanceStatus: filter.attendanceStatus,
                        column: sort?.column ? sort.column : undefined,
                        direction: sort?.direction ? sort.direction : undefined,
                    })
                }
                rowsPreprocessor={(row) => ({
                    ...row,
                    deletionId: row.id,
                    admissionNumber: row.admissionNumber,
                    status: (
                        <Box
                            display="flex"
                            gap={2}
                            alignItems="center"
                            flexWrap="wrap"
                        >
                            <Typography variant="subtitle2">
                                <strong>Present:</strong> {row.presentCount}
                            </Typography>
                            <Typography variant="subtitle2">
                                <strong>Absent:</strong> {row.absentCount}
                            </Typography>
                            <Typography variant="subtitle2">
                                <strong>Late:</strong> {row.lateCount}
                            </Typography>
                            <Typography variant="subtitle2">
                                <strong>Leave:</strong> {row.leaveCount}
                            </Typography>
                        </Box>
                    ),
                    Percentage: (
                        <Box
                            display="flex"
                            gap={2}
                            alignItems="center"
                            flexWrap="wrap"
                        >
                            <Typography variant="subtitle2">
                                <strong>Present:</strong>{" "}
                                {row.presentPercentage}
                            </Typography>
                            <Typography variant="subtitle2">
                                <strong>Absent:</strong> {row.absentPercentage}
                            </Typography>
                            <Typography variant="subtitle2">
                                <strong>Late:</strong> {row.leavePercentage}
                            </Typography>
                            <Typography variant="subtitle2">
                                <strong>Leave:</strong> {row.latePercentage}
                            </Typography>
                            <Typography variant="subtitle2">
                                <strong>Off Day:</strong> {row.offDayPercentage}
                            </Typography>
                            <Typography variant="subtitle2">
                                <strong>Sick Leave:</strong>{" "}
                                {row.sickLeavePercentage}
                            </Typography>
                        </Box>
                    ),
                })}
                readables={{
                    admissionNumber: "Reg No.",
                    enrollmentNo: "Enroll No",
                    fileNo: "FileNo",
                    studentName: "Name",
                    fatherName: "Father Name",
                    status: "Status",
                    Percentage: "%Age",
                }}
                editables={[]}
                customCols={[]}
                updatingAgents={[
                    filter.apply,
                    filter.search,
                    changes,
                    sort?.direction,
                ]}
                ops={{
                    read: "READ ATTENDANCE",
                    edit: "",
                    delete: "DELETE ATTENDANCE",
                }}
                storageKey="Over All Attandance"
                getRows={(row) => {
                    setTotalAttendanceTaken(row);
                }}
                onSort={(row: any) => {
                    setSort(row);
                }}
                unsortableCols={["status", "Percentage"]}
            />

            {/* <Typography
                variant="subtitle1"
                style={{ display: "flex", justifyContent: "flex-end" }}
            >
                Total Attendance Taken : {""}
                {totalAttendanceTaken[0]?.TotalAttendance}
            </Typography> */}

            {filter?.apply ? (
                <Box
                    display="flex"
                    gap={2}
                    alignItems="center"
                    justifyContent={"flex-end"}
                    flexWrap="wrap"
                >
                    <Typography variant="subtitle1">
                        <strong>Total Attendance Taken : </strong>{" "}
                        {totalAttendanceTaken &&
                            totalAttendanceTaken[0]?.TotalAttendance}
                    </Typography>
                </Box>
            ) : undefined}
        </div>
    );
};

export default OverAllAttendance;
