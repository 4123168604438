import { CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";
import { lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import Authenticate from "../../Authenticate";
// import AddClient from "../../Suepradmin/AddClient";
// import { AddSuperadmin } from "../../Suepradmin/AddSuperAdmin";
import Clients from "../../superAdmin/Clients";
import SuperAdmins from "../../superAdmin/SuperAdmins";
import { AddClassroom } from "../Academics/classroom/AddClassroom";
import { Classroom } from "../Academics/classroom/Classroom";
import AddPublisher from "../Academics/Publisher/AddPublisher";
import Publisher from "../Academics/Publisher/Publisher";
import { Section } from "../Academics/Section/Section";
import AddSession from "../Academics/Session/AddSession";
import Session from "../Academics/Session/Session";
import AddSubject from "../Academics/Subject/AddSubject";
import { Subject } from "../Academics/Subject/Subject";
import AddCampus from "../admin/campus/AddCampus";
import Campus from "../admin/campus/Campus";
import AddUser from "../admin/users/AddUser";
import User from "../admin/users/User";
// import { AddStudent } from "../Admission/Student/AddStudent";
import AddStudentCategory from "../Admission/Student/category/AddStudentCategory";
import { StudentCategory } from "../Admission/Student/category/StudentCategory";
import ImportFromExcel from "../Admission/Student/ImportFromExcel";
import { PromoteSection } from "../Admission/Student/PromoteSection";
import { CountReport } from "../Admission/Student/reports/CountReport";
import { WithdrawlRegister } from "../Admission/Student/reports/WithdrawlRegister";
import { ShowStudent } from "../Admission/Student/ShowStudent";
// import { Student } from "../Admission/Student/Student";
import { Error404 } from "../Error404";
import { CreateFamily } from "../Family/CreateFamily";
import { ViewFamily } from "../Family/ViewFamily";
import { AddFeeCategory } from "../Fee/AddFeeCategory";
import AddFeeDiscount from "../Fee/AddFeeDiscount";
import AddFeeStructure from "../Fee/AddFeeStructure";
// import { ApplyFees } from "../Fee/ApplyFees";
// import { CollectFee } from "../Fee/CollectFee";
// import { EditFees } from "../Fee/EditFees";
import { FeeCategory } from "../Fee/FeeCategory";
import FeeDiscount from "../Fee/FeeDiscount";
import FeeStructure from "../Fee/FeeStructure";
import { Receipt } from "../Fee/Receipt";
import { FamilyReceipt } from "../Fee/Receipt/FamilyReceipt";
import { MultipleStudentsReceipt } from "../Fee/Receipt/MultipleStudentsReceipt";
import { DailyCollection } from "../Fee/Reports/DailyCollection";
import { DefaulterReport } from "../Fee/Reports/DefaulterReport";
// import { FeeGenerated } from "../Fee/Reports/FeeGenerated";
import { useTheme } from "@material-ui/core";
import { MultiPermissionAuthorize } from "../../MultiPermissionAuthorize";
import ChangeCampus from "../Admission/ChangeCampus";
import { AssignedStudentCategory } from "../Admission/Student/AssignedStudentCategory";
import { ChangeStudent } from "../Admission/Student/ChangeStudent";
import { CompleteStudent } from "../Admission/Student/CompleteStudent";
import { DeletePromotion } from "../Admission/Student/DeletePromotion";
import AddStudentAttendance from "../Attendance/StudentAttendance/AddStudentAttendance";
import AddStudentLeave from "../Attendance/StudentAttendance/AddStudentLeave";
import BlankAttandanceList from "../Attendance/StudentAttendance/BlankAttendanceList";
import OverAllAttendance from "../Attendance/StudentAttendance/OverAllAttendance";
import StudentAttendance from "../Attendance/StudentAttendance/StudentAttendance";
import StudentLeave from "../Attendance/StudentAttendance/StudentLeave";
import StudentPercentageAttendance from "../Attendance/StudentAttendance/StudentPercentageAttendance";
import UpdateStudentAttendanceBulk from "../Attendance/StudentAttendance/UpdateStudentAttendanceBulk";
import Certificates from "../Certificates/Certificates";
import CreateCertificate from "../Certificates/CreateCertificate";
import CertificateHistory from "../Certificates/History/CertificateHistory";
import EditCertificate from "../CertificatesCopy/EditTemplate";
import FeeList from "../Fee/FeeList";
import ShowFeeStructure from "../Fee/ShowFeeStructure";
import AddBank from "../Finance/AddBank";
import Banks from "../Finance/Banks";
import { Homepage } from "../Homepage";
import AddAuthor from "../Library/Author/AddAuthor";
import Author from "../Library/Author/Author";
import AddBook from "../Library/Book/AddBook";
import Book from "../Library/Book/Book";
import BookHistory from "../Library/BookHistory";
import AddGenre from "../Library/Genre/AddGenre";
import Genre from "../Library/Genre/Genre";
import IssueReturn from "../Library/IssueReturn/IssueReturn";
import AddStock from "../Library/Stock/AddStock";
import Stock from "../Library/Stock/Stock";
import Login from "../Login/Login";
import { Logs } from "../LogSystem/Logs";
import CreateRole from "../Rolesystem/CreateRole";
import Roles from "../Rolesystem/Roles";
import Settings from "../Settings/Settings";
import AddTestResult from "../TestResult/AddTestResult";
import Test from "../TestResult/Test";
import TestResult from "../TestResult/TestResult";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
    ({ theme, open }) => ({
        flexGrow: 1,
        padding: theme.spacing(1),
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: 0,
        ...(open && {
            transition: theme.transitions.create("margin", {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    })
);

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),

    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
}));

const AddClient = lazy(() => import("../../superAdmin/AddClient"));
const ApplyFees = lazy(() => import("../Fee/ApplyFees"));
const AddSuperadmin = lazy(() => import("../../superAdmin/AddSuperAdmin"));
const AddStudent = lazy(() => import("../Admission/Student/AddStudent"));
const Student = lazy(() => import("../Admission/Student/Student"));
const CollectFee = lazy(() => import("../Fee/CollectFee"));
const EditFees = lazy(() => import("../Fee/EditFees"));
const FeeGenerated = lazy(() => import("../Fee/Reports/FeeGenerated"));

const RouteComponent = ({ children }) => {
    return (
        <Suspense
            fallback={
                <div>
                    <CircularProgress />
                </div>
            }
        >
            <Authenticate>{children}</Authenticate>
        </Suspense>
    );
};

const MainView = ({ open }) => {
    const theme = useTheme();

    return (
        <Main
            open={open}
            style={{
                height: "100%",
                width: `calc(100% - ${drawerWidth}px)`,
                marginTop: theme.spacing(9),
            }}
        >
            <Switch>
                <Route exact path="/">
                    <Authenticate>
                        <Homepage />
                    </Authenticate>
                </Route>

                <Route exact path="/clients">
                    <Authenticate>
                        <Clients />
                    </Authenticate>
                </Route>

                <Route exact path="/add-client">
                    <RouteComponent>
                        <AddClient />
                    </RouteComponent>
                </Route>

                <Route exact path="/login">
                    <Login variant="org" />
                </Route>

                <Route exact path="/_superadminLogin">
                    <Login variant="superadmin" />
                </Route>

                {/* Roles */}
                <Route exact path="/add-role">
                    <Authenticate>
                        <CreateRole />
                    </Authenticate>
                </Route>

                <Route exact path="/edit-role">
                    <Authenticate>
                        <CreateRole mode="edit" />
                    </Authenticate>
                </Route>

                <Route exact path="/read-role">
                    <Authenticate>
                        <CreateRole mode="read" />
                    </Authenticate>
                </Route>

                <Route exact path="/role">
                    <Authenticate>
                        <Roles />
                    </Authenticate>
                </Route>

                {/* Campus */}
                <Route exact path="/campuses">
                    <Authenticate>
                        <Campus />
                    </Authenticate>
                </Route>
                <Route exact path="/add-campus">
                    <Authenticate>
                        <AddCampus />
                    </Authenticate>
                </Route>

                <Route exact path="/super-admin">
                    <Authenticate>
                        <SuperAdmins />
                    </Authenticate>
                </Route>

                <Route exact path="/add-super-admin">
                    <RouteComponent>
                        <AddSuperadmin />
                    </RouteComponent>
                </Route>

                {/* Users */}
                <Route exact path="/user">
                    <Authenticate>
                        <User />
                    </Authenticate>
                </Route>
                <Route exact path="/add-user">
                    <Authenticate>
                        <AddUser />
                    </Authenticate>
                </Route>

                {/* Academics */}
                <Route exact path="/session">
                    <Authenticate>
                        <Session />
                    </Authenticate>
                </Route>
                <Route exact path="/add-session">
                    <Authenticate>
                        <AddSession />
                    </Authenticate>
                </Route>

                <Route exact path="/add-classroom" userType="admin">
                    <Authenticate>
                        <AddClassroom />
                    </Authenticate>
                </Route>

                <Route exact path="/change-campus">
                    <Authenticate>
                        <ChangeCampus />
                    </Authenticate>
                </Route>

                <Route exact path="/student-delete-promotion">
                    <Authenticate>
                        <DeletePromotion />
                    </Authenticate>
                </Route>

                <Route exact path="/student-complete">
                    <Authenticate>
                        <CompleteStudent />
                    </Authenticate>
                </Route>

                <Route exact path="/assign-student-category">
                    <Authenticate>
                        <AssignedStudentCategory />
                    </Authenticate>
                </Route>

                <Route exact path="/classroom">
                    <Authenticate>
                        <Classroom />
                    </Authenticate>
                </Route>

                <Route exact path="/section">
                    <Authenticate>
                        <Section />
                    </Authenticate>
                </Route>
                <Route exact path="/add-publisher">
                    <Authenticate>
                        <AddPublisher />
                    </Authenticate>
                </Route>

                <Route exact path="/publisher">
                    <Authenticate>
                        <Publisher />
                    </Authenticate>
                </Route>

                <Route exact path="/add-subject">
                    <Authenticate>
                        <AddSubject />
                    </Authenticate>
                </Route>
                <Route exact path="/subject">
                    <Authenticate>
                        <Subject />
                    </Authenticate>
                </Route>

                {/* Student */}
                <Route exact path="/add-student">
                    <RouteComponent>
                        <AddStudent />
                    </RouteComponent>
                </Route>

                <Route exact path="/student">
                    <RouteComponent>
                        <Student />
                    </RouteComponent>
                </Route>

                <Route exact path="/student/withdrawl-register">
                    <Authenticate>
                        <WithdrawlRegister />
                    </Authenticate>
                </Route>

                <Route exact path="/user/certificate/create">
                    <Authenticate>
                        <CreateCertificate mode="create" />
                    </Authenticate>
                </Route>

                <Route exact path="/user/certificate">
                    <Authenticate>
                        <Certificates />
                    </Authenticate>
                </Route>

                <Route exact path="/user/certificate/assign">
                    <Authenticate>
                        <CertificateHistory />
                    </Authenticate>
                </Route>

                <Route exact path="/user/certificate/update/:id">
                    <Authenticate>
                        <MultiPermissionAuthorize ops={["UPDATE CERTIFICATE"]}>
                            <CreateCertificate mode="update" />
                        </MultiPermissionAuthorize>
                    </Authenticate>
                </Route>

                <Route exact path="/student/promote-section">
                    <Authenticate>
                        <PromoteSection />
                    </Authenticate>
                </Route>

                <Route exact path="/student/change-student">
                    <Authenticate>
                        <ChangeStudent />
                    </Authenticate>
                </Route>

                <Route exact path="/show-student/:username">
                    <Authenticate>
                        <ShowStudent />
                    </Authenticate>
                </Route>

                <Route exact path="/import-from-excel">
                    <Authenticate>
                        <ImportFromExcel />
                    </Authenticate>
                </Route>

                <Route exact path="/student/count-report">
                    <Authenticate>
                        <CountReport />
                    </Authenticate>
                </Route>

                {/* Student Category */}
                <Route exact path="/add-student-category">
                    <Authenticate>
                        <AddStudentCategory />
                    </Authenticate>
                </Route>

                <Route exact path="/add-test-result">
                    <Authenticate>
                        <AddTestResult />
                    </Authenticate>
                </Route>

                <Route exact path="/add-student-attendance">
                    <Authenticate>
                        <AddStudentAttendance />
                    </Authenticate>
                </Route>

                <Route exact path="/student-blank-attendance-list">
                    <Authenticate>
                        <BlankAttandanceList />
                    </Authenticate>
                </Route>

                <Route exact path="/attendance">
                    <Authenticate>
                        <StudentAttendance />
                    </Authenticate>
                </Route>

                <Route exact path="/overall-attendance">
                    <Authenticate>
                        <OverAllAttendance />
                    </Authenticate>
                </Route>

                <Route exact path="/attendance-percentage">
                    <Authenticate>
                        <StudentPercentageAttendance />
                    </Authenticate>
                </Route>

                <Route exact path="/add-student-leave">
                    <Authenticate>
                        <AddStudentLeave />
                    </Authenticate>
                </Route>

                <Route exact path="/student-leave">
                    <Authenticate>
                        <StudentLeave />
                    </Authenticate>
                </Route>

                <Route exact path="/update-student-attendance">
                    <Authenticate>
                        <UpdateStudentAttendanceBulk />
                    </Authenticate>
                </Route>

                <Route exact path="/test-result">
                    <Authenticate>
                        <TestResult />
                    </Authenticate>
                </Route>

                <Route exact path="/test">
                    <Authenticate>
                        <Test />
                    </Authenticate>
                </Route>

                <Route exact path="/student-category">
                    <Authenticate>
                        <StudentCategory />
                    </Authenticate>
                </Route>

                {/* Fee Module */}
                <Route exact path="/fee-category">
                    <Authenticate>
                        <FeeCategory />
                    </Authenticate>
                </Route>

                <Route exact path="/add-fee-category">
                    <Authenticate>
                        <AddFeeCategory />
                    </Authenticate>
                </Route>

                <Route exact path="/fee-structure">
                    <Authenticate>
                        <FeeStructure />
                    </Authenticate>
                </Route>

                <Route exact path="/add-fee-structure">
                    <Authenticate>
                        <AddFeeStructure />
                    </Authenticate>
                </Route>

                <Route exact path="/fee-discount">
                    <Authenticate>
                        <FeeDiscount />
                    </Authenticate>
                </Route>

                <Route exact path="/add-fee-discount">
                    <Authenticate>
                        <AddFeeDiscount />
                    </Authenticate>
                </Route>

                <Route exact path="/apply-fees">
                    <RouteComponent>
                        <ApplyFees variant="classroom" />
                    </RouteComponent>
                </Route>

                <Route exact path="/fee">
                    <RouteComponent>
                        <FeeList />
                    </RouteComponent>
                </Route>

                <Route exact path="/edit-fees">
                    <RouteComponent>
                        <EditFees />
                    </RouteComponent>
                </Route>

                <Route exact path="/show-fee-structure/:id">
                    <Authenticate>
                        <ShowFeeStructure />
                    </Authenticate>
                </Route>

                <Route exact path="/apply-fees-student">
                    <RouteComponent>
                        <ApplyFees variant="student" />
                    </RouteComponent>
                </Route>

                <Route exact path="/collect-fees">
                    <RouteComponent>
                        <CollectFee />
                    </RouteComponent>
                </Route>

                <Route exact path="/fees/receipt/multiple">
                    <Authenticate>
                        <MultipleStudentsReceipt />
                    </Authenticate>
                </Route>

                <Route exact path="/fees/reports/fee-generated">
                    <RouteComponent>
                        <FeeGenerated />
                    </RouteComponent>
                </Route>

                <Route exact path="/fees/reports/defaulters">
                    <RouteComponent>
                        <DefaulterReport />
                    </RouteComponent>
                </Route>

                <Route exact path="/fees/reports/daily-collection">
                    <RouteComponent>
                        <DailyCollection />
                    </RouteComponent>
                </Route>

                <Route exact path="/fees/receipt/show-single">
                    <Authenticate>
                        <Receipt />
                    </Authenticate>
                </Route>

                <Route exact path="/family/create">
                    <Authenticate>
                        <CreateFamily />
                    </Authenticate>
                </Route>

                <Route exact path="/family">
                    <Authenticate>
                        <ViewFamily />
                    </Authenticate>
                </Route>

                <Route exact path="/edit-family">
                    <Authenticate>
                        <CreateFamily editMode />
                    </Authenticate>
                </Route>

                <Route exact path="/family/receipt">
                    <Authenticate>
                        <FamilyReceipt />
                    </Authenticate>
                </Route>

                {/* Finance */}
                <Route exact path="/banks">
                    <Authenticate>
                        <Banks />
                    </Authenticate>
                </Route>

                <Route exact path="/add-bank">
                    <Authenticate>
                        <AddBank />
                    </Authenticate>
                </Route>

                {/*========================================== Library ==========================================*/}
                <Route exact path="/library/authors">
                    <Authenticate>
                        <Author />
                    </Authenticate>
                </Route>

                <Route exact path="/library/authors/add">
                    <Authenticate>
                        <AddAuthor />
                    </Authenticate>
                </Route>

                <Route exact path="/library/genres">
                    <Authenticate>
                        <Genre />
                    </Authenticate>
                </Route>

                <Route exact path="/library/genres/add">
                    <Authenticate>
                        <AddGenre />
                    </Authenticate>
                </Route>

                <Route exact path="/library/books">
                    <Authenticate>
                        <Book />
                    </Authenticate>
                </Route>

                <Route exact path="/library/books/add">
                    <Authenticate>
                        <AddBook />
                    </Authenticate>
                </Route>

                <Route exact path="/library/stock">
                    <Authenticate>
                        <Stock />
                    </Authenticate>
                </Route>

                <Route exact path="/library/stock/add">
                    <Authenticate>
                        <AddStock />
                    </Authenticate>
                </Route>

                <Route exact path="/library/issue-return">
                    <Authenticate>
                        <IssueReturn />
                    </Authenticate>
                </Route>

                <Route exact path="/library/history">
                    <Authenticate>
                        <BookHistory />
                    </Authenticate>
                </Route>

                {/*================================== CERTIFICATTES ==================================*/}
                <Route exact path="/certificates">
                    <Authenticate>
                        <Certificates />
                    </Authenticate>
                </Route>

                <Route exact path="/certificates/edit">
                    <Authenticate>
                        <EditCertificate />
                    </Authenticate>
                </Route>

                {/*======================================= Logs =======================================*/}

                <Route exact path="/logs">
                    <Authenticate>
                        <Logs />
                    </Authenticate>
                </Route>

                {/*===================================== Settings =====================================*/}
                <Route path="/settings">
                    <Authenticate>
                        <Settings />
                    </Authenticate>
                </Route>

                {/* Wildcard 404 Page  */}
                <Route path="*" exact>
                    <Error404 />
                </Route>
            </Switch>
        </Main>
    );
};

export default MainView;
