import { ClearAll } from "@mui/icons-material";
import {
    Button,
    Grid,
    IconButton,
    Tooltip,
    Typography,
    useTheme,
} from "@mui/material";
import { useState } from "react";
import BaseAccordian from "../../../BaseAccordian";
import { MultiPermissionAuthorize } from "../../../MultiPermissionAuthorize";
import { GenreService } from "../../../services/GenreService";
import AddView from "../../AddView";
import { Search } from "../../Filters/Search";
import { RUDTable } from "../../Table/RUDTable";
import AddGenre from "./AddGenre";

const Genre = () => {
    const [filter, setFilter] = useState<any>({ search: "", apply: 0 });
    const [clearFilters, setClearFilters] = useState(false);
    const [changes, setChanges] = useState(0);
    const theme = useTheme();
    const [sort, setSort] = useState<{ column: ""; direction: "" }>();

    const handleFilterClear = () => {
        setClearFilters(!clearFilters);
        setFilter({ search: "" });
    };

    return (
        <div>
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Typography variant="h4" color="primary" gutterBottom>
                        Genres
                    </Typography>
                </Grid>

                <MultiPermissionAuthorize ops={["CREATE GENRES"]}>
                    <Grid>
                        <AddView
                            label="add genre"
                            triggerRowsRefetch={() => setChanges(changes + 1)}
                        >
                            <AddGenre />
                        </AddView>
                    </Grid>
                </MultiPermissionAuthorize>
            </Grid>

            <BaseAccordian>
                <Grid item container spacing={2}>
                    <Grid item xs={12} md={4}>
                        {" "}
                        <Search
                            filter={filter}
                            setFilter={setFilter}
                            clear={clearFilters}
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Button
                            color="primary"
                            fullWidth
                            variant="outlined"
                            onClick={() =>
                                setFilter({
                                    ...filter,
                                    apply: filter.apply + 1,
                                })
                            }
                            style={{ height: "100%" }}
                            size="small"
                        >
                            {" "}
                            search
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Tooltip title="Clear Filters">
                            <IconButton
                                onClick={handleFilterClear}
                                color="primary"
                            >
                                <ClearAll />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </BaseAccordian>

            <RUDTable
                getter={(page, limit) =>
                    GenreService.getGenre(page, limit, {
                        search: filter.search ? filter.search : undefined,
                        column: sort?.column ? sort.column : undefined,
                        direction: sort?.direction ? sort.direction : undefined,
                    })
                }
                editor={GenreService.editGenre}
                deletor={(id: string | number) =>
                    GenreService.deleteGenreSoft(id)
                }
                rowsPreprocessor={(row) => ({
                    ...row,
                    deletionId: row.id,
                    _description: row.description ? (
                        row.description
                    ) : (
                        <Typography style={{ color: theme.palette.divider }}>
                            N/A
                        </Typography>
                    ),
                })}
                readables={{
                    id: "ID",
                    name: "Name",
                    _description: "Description",
                }}
                editables={[
                    {
                        name: "name",
                        type: "textfield",
                        label: "Name",
                    },

                    {
                        name: "description",
                        type: "textfield",
                        label: "Description",
                        fieldProps: { multiline: true },
                    },
                ]}
                defaultSelected={["name"]}
                updatingAgents={[filter.apply, changes, sort?.direction]}
                ops={{
                    read: "READ GENRES",
                    edit: "UPDATE GENRES",
                    delete: "DELETE GENRES",
                }}
                storageKey="genres"
                onSort={(rows: any) => {
                    setSort(rows);
                }}
            />
        </div>
    );
};

export default Genre;
