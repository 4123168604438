import {
    ArrowDownward,
    ArrowUpward,
    Circle,
    ClearAll,
    ToggleOff,
} from "@mui/icons-material";
import {
    Button,
    Chip,
    Grid,
    IconButton,
    Tooltip,
    Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import BaseAccordian from "../../../../BaseAccordian";
import { MultiPermissionAuthorize } from "../../../../MultiPermissionAuthorize";
import { StudentCategoryService } from "../../../../services/StudentCategoryService";
import AddView from "../../../AddView";
import { AppContext } from "../../../AppContext";
import { Search } from "../../../Filters/Search";
import { RUDTable } from "../../../Table/RUDTable";
import FeedbackSnackbar from "../../../TestResult/FeedBackSnackBar";
import AddStudentCategory from "./AddStudentCategory";
import EditStudentCategory from "./EditStudentCategory";

export function StudentCategory() {
    const [showWithDelay, setShowWithDelay] = useState(false);
    const { user, feedback, setFeedback } = useContext(AppContext);
    const [filter, setfilter] = useState<any>({ search: "", apply: 0 });
    const [changes, setChanges] = useState(0);
    const [clearFilters, setClearFilters] = useState(false);
    const [sort, setSort] = useState<any>();

    const handleFilterClear = () => {
        setClearFilters(!clearFilters);
        setfilter({ search: "" });
    };

    const ChangeStudentCategorySequence = ({ row, setChangesCount }: any) => {
        const handleLevelChange = async (direction: string) => {
            const [data, err] = await StudentCategoryService.changeLevel(
                row.id,
                direction
            );

            if (data) {
                setChanges((c: number) => c + 1);
            }
        };

        return (
            <div>
                <IconButton
                    onClick={() => handleLevelChange("up")}
                    size="small"
                >
                    <Tooltip title="Move up">
                        <ArrowUpward fontSize="small" />
                    </Tooltip>
                </IconButton>

                <IconButton
                    onClick={() => handleLevelChange("down")}
                    size="small"
                >
                    <Tooltip title="Move down">
                        <ArrowDownward fontSize="small" />
                    </Tooltip>
                </IconButton>
            </div>
        );
    };

    useEffect(() => {
        let timer: any;
        if (feedback.show) {
            // Start the delay timer
            timer = setTimeout(() => {
                setShowWithDelay(true);
            }, 500); // Adjust the delay time in milliseconds
        } else {
            // Reset visibility when feedback.show is false
            setShowWithDelay(false);
        }

        return () => clearTimeout(timer); // Clean up the timer
    }, [feedback.show]);

    return (
        <div>
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Typography variant="h4" color="primary" gutterBottom>
                        Student Category
                    </Typography>
                </Grid>

                <Grid item>
                    <MultiPermissionAuthorize ops={["CREATE STUDENT_CATEGORY"]}>
                        <AddView
                            label="add student category"
                            triggerRowsRefetch={() => setChanges(changes + 1)}
                        >
                            <AddStudentCategory />
                        </AddView>
                    </MultiPermissionAuthorize>
                </Grid>
            </Grid>

            <BaseAccordian>
                <Grid item container spacing={2}>
                    <Grid item xs={12} md={4}>
                        {" "}
                        <Search
                            filter={filter}
                            setFilter={setfilter}
                            clear={clearFilters}
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Button
                            color="primary"
                            fullWidth
                            variant="outlined"
                            onClick={() =>
                                setfilter({
                                    ...filter,
                                    apply: filter.apply + 1,
                                })
                            }
                            style={{ height: "100%" }}
                            size="small"
                        >
                            {" "}
                            search
                        </Button>
                    </Grid>
                    <Grid item xs={2}>
                        <Tooltip title="Clear Filters">
                            <IconButton
                                onClick={handleFilterClear}
                                color="primary"
                            >
                                <ClearAll />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </BaseAccordian>
            <RUDTable
                getter={(page, limit) =>
                    StudentCategoryService.getStudentCategory(page, limit, {
                        search: filter.search ? filter.search : undefined,
                        column: sort.column ? sort.column : undefined,
                        direction: sort.direction ? sort.direction : undefined,
                    })
                }
                editor={StudentCategoryService.editStudentCategory}
                deletor={
                    user?.baseUser?.ops.includes("DELETE STUDENT_CATEGORY")
                        ? (id: string | number) =>
                              StudentCategoryService.deleteStudentCategorySoft(
                                  id
                              )
                        : undefined
                }
                rowsPreprocessor={(row) => ({
                    ...row,
                    //in here we will map nested values
                    deletionId: row.id,
                    _status: (
                        <Chip
                            variant="outlined"
                            icon={
                                row.status === "active" ? (
                                    <Circle color="success" fontSize="small" />
                                ) : (
                                    <ToggleOff fontSize="small" color="error" />
                                )
                            }
                            label={row.status}
                            sx={{ textTransform: "capitalize" }}
                            color={
                                row.status === "active" ? "success" : "error"
                            }
                            size="small"
                        />
                    ),
                })}
                readables={{
                    id: "ID",
                    name: "Name",
                    _status: "Status",
                    Changesequence: "Change Sequence",
                    Edit: "Edit",
                }}
                customCols={[
                    {
                        header: "Changesequence",
                        content: (row) => (
                            <ChangeStudentCategorySequence
                                row={row}
                                setChangesCount={setChanges}
                            />
                        ),
                    },
                    {
                        header: user?.baseUser?.ops.includes(
                            "UPDATE STUDENT_CATEGORY"
                        )
                            ? "Edit"
                            : "",
                        content: (row) => (
                            <MultiPermissionAuthorize
                                ops={["UPDATE STUDENT_CATEGORY"]}
                            >
                                <AddView
                                    label="Edit "
                                    updateMode
                                    triggerRowsRefetch={() =>
                                        setChanges(changes + 1)
                                    }
                                >
                                    <EditStudentCategory row={row} updateMode />
                                </AddView>
                            </MultiPermissionAuthorize>
                        ),
                    },
                ]}
                updatingAgents={[filter.apply, changes, sort?.direction]}
                ops={{
                    read: "READ STUDENT_CATEGORY",
                    edit: "",
                    delete: "DELETE STUDENT_CATEGORY",
                }}
                storageKey="student Category"
                onSort={(row) => {
                    setSort(row);
                }}
                unsortableCols={["Edit"]}
            />

            {showWithDelay && (
                <FeedbackSnackbar
                    feedback={feedback}
                    setFeedback={setFeedback}
                />
            )}
        </div>
    );
}
