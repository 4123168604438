import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { UserService } from "../../../services/UserService";
import { AppContext } from "../../AppContext";

const AttentionDialog = ({ dialogOpen, setDialogOpen }: any) => {
    const history = useHistory();
    const { setUser, setUserType, setFeedback, feedback } =
        useContext(AppContext);

    const handleLogout = async () => {
        const [data, err] = await UserService.logoutUser();
        setUser(null);
        setUserType(null);
        setDialogOpen(false);
        setFeedback({
            show: false,
            loading: false,
            severity: "" as any,
            message: "",
            respneseDetail: [],
        });

        if (data) {
            history.push("/login");
        }
    };

    const handleIgnore = () => {
        setDialogOpen(false); // Simply close the dialog
    };

    return (
        <div>
            <Dialog open={dialogOpen} onClose={handleIgnore}>
                <DialogTitle>Changes Applied</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Your user changes have been updated. To see the changes,
                        you need to logout and log back in. Would you like to
                        logout now?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleIgnore}
                        color="error"
                        variant="outlined"
                    >
                        Ignore
                    </Button>
                    <Button
                        onClick={handleLogout}
                        color="primary"
                        autoFocus
                        variant="contained"
                    >
                        Logout
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default AttentionDialog;
