import { Close, Image } from "@mui/icons-material";
import Upload from "@mui/icons-material/Upload";
import {
    Button,
    CircularProgress,
    Grid,
    IconButton,
    Modal,
    TextField,
} from "@mui/material";
import { ChangeEvent, useContext, useState } from "react";
import { S3Service } from "../../../services/S3Service";
import { StudentAttendanceService } from "../../../services/StudentAttendanceService";
import { AddViewContext } from "../../AddView";
import { AppContext } from "../../AppContext";
import { Autocomplete } from "../../AutoComplete";
import { awsDirStruct } from "../../constants";

const AddStudentLeave = () => {
    const { user } = useContext(AppContext);
    const { feedback, setFeedback } = useContext(AppContext);
    const { open, setOpen, triggerRowsRefetch } = useContext(AddViewContext);
    const [showImage, setShowImage] = useState(false);

    const [studentLeave, setStudentLeave] = useState<any>({
        attendanceDate: "",
        subject: "",
        user: user?.baseUser?.id,
        campus:
            user?.baseUser?.userRegions?.length > 1
                ? ""
                : user?.baseUser?.userRegions[0]?.campus?.id,
        classroom: "",
        type: "dayWise",
        session: "",
        section: "",
        leaveApplication: "",
        leaveStatus: "pending",
        description:
            "Respected Teacher,\nI am writing to inform you that I am unable to attend school due to [Reason: urgent personal matter/illness/other specific reason]. \n \nI kindly request you to grant me leave. I assure you that I will catch up on any missed work during my absence.",
        applicationType: "",
        student: "",
        leaveApplicationName: "",
        fileNo: "",
        attendanceStatus: "pending",
    });

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            setStudentLeave({
                ...studentLeave,
                leaveApplication: event.target.files[0],
                leaveApplicationName: event.target.files[0].name,
            });
        }
    };

    const handlesubmit = async (ev: any) => {
        const date = new Date();
        const formattedDate = date.toISOString().split("T")[0];
        ev.preventDefault();
        setFeedback({ ...feedback, loading: true });
        const url =
            studentLeave.student +
            studentLeave.fileNo +
            studentLeave.leaveApplicationName;

        const key = awsDirStruct.attendance.leaveImages + "/" + url;

        const [data, err] =
            await StudentAttendanceService.createSingleStudentAttendance({
                ...studentLeave,
                leaveApplication: url,
                attendanceDate: formattedDate,
                subject: undefined,
            });

        if (data) {
            setFeedback({
                loading: false,
                message: data.message,
                severity: "success",
                show: true,
            });

            if (studentLeave.leaveApplication) {
                const [data, err] = await S3Service.uploadToS3(
                    key,
                    studentLeave.leaveApplication
                );
            }

            setOpen(false);
            triggerRowsRefetch();
        } else {
            setFeedback({
                loading: false,
                message: err,
                severity: "error",
                show: true,
            });
        }
    };

    return (
        <>
            <Grid item xs={12}>
                {/* {feedback.show && (
                    <Alert severity={feedback.severity}>
                        {feedback.message}
                    </Alert>
                )} */}
            </Grid>
            <Grid
                container
                component={"form"}
                onSubmit={handlesubmit}
                gap={2}
                sx={{ pt: "1rem" }}
            >
                <Grid item xs={12}>
                    <Autocomplete
                        defaultOptions={[
                            {
                                label: "Sick Leave",
                                status: "sickeLeave",
                                id: "Sick Leave",
                            },
                            {
                                label: "Urgent Leave",
                                status: "urgentLeave",
                                id: "Urgent Leave",
                            },
                            {
                                label: "Other",
                                status: "Other",
                                id: "Other",
                            },
                        ]}
                        setOutput={(c: any) =>
                            setStudentLeave({
                                ...studentLeave,
                                applicationType: c?.id || "",
                            })
                        }
                        label="Application Type"
                        labelKey="label"
                        textFieldProps={{
                            variant: "outlined",
                            size: "small",
                            required: true,
                        }}
                        // clear={clearFilters}
                    />
                </Grid>
                <Grid xs={12}>
                    <TextField
                        fullWidth
                        label="Application Picture"
                        InputProps={{
                            endAdornment: (
                                <IconButton
                                    onClick={() =>
                                        setStudentLeave({
                                            ...studentLeave,
                                            leaveApplication: null,
                                        })
                                    }
                                >
                                    <Close />
                                </IconButton>
                            ),
                            startAdornment: studentLeave.leaveApplication ? (
                                <IconButton onClick={() => setShowImage(true)}>
                                    <Image />
                                </IconButton>
                            ) : (
                                <IconButton component="label">
                                    <Upload />
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={handleFileChange}
                                        hidden
                                    />
                                </IconButton>
                            ),
                        }}
                        value={
                            studentLeave.leaveApplication
                                ? studentLeave.leaveApplication
                                : "Choose file"
                        }
                        size="small"
                    />

                    {studentLeave?.leaveApplication && (
                        <Modal
                            onClose={() => setShowImage(false)}
                            open={showImage}
                            sx={{
                                padding: 4,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <img
                                src={URL.createObjectURL(
                                    studentLeave.leaveApplication
                                )}
                                style={{
                                    width: "40%",
                                    borderRadius: 2,
                                }}
                            />
                        </Modal>
                    )}
                </Grid>

                {user?.baseUser?.userRegions?.length > 1 && (
                    <Grid item xs={12}>
                        <Autocomplete
                            api="/org/campus"
                            setOutput={(c: any) =>
                                setStudentLeave({
                                    ...studentLeave,
                                    campus: c?.id || "",
                                })
                            }
                            label="Campus"
                            labelKey="name"
                            textFieldProps={{
                                variant: "outlined",
                                size: "small",
                            }}
                        />
                    </Grid>
                )}

                <Grid item xs={12}>
                    <Autocomplete
                        api="/org/student"
                        setOutput={(c: any) =>
                            setStudentLeave({
                                ...studentLeave,
                                student: c?.id || "",
                                classroom: c.classroom.id,
                                section: c.section.id,
                                session: c.session.id,
                                fileNo: c.fileNo,
                            })
                        }
                        label="Student"
                        labelKey="name"
                        textFieldProps={{
                            variant: "outlined",
                            size: "small",
                        }}
                        processor={(opt) => ({
                            ...opt,
                            name: opt?.baseUser?.name,
                            id: opt?.id,
                        })}
                        apiParams={{
                            campus: studentLeave.campus
                                ? studentLeave.campus
                                : "",
                        }}
                    />
                </Grid>
                <Grid xs={12}>
                    <TextField
                        fullWidth
                        label="Description"
                        name="description"
                        variant="outlined"
                        size="small"
                        minRows={4}
                        value={studentLeave.description}
                        multiline
                        onChange={(ev: any) => {
                            setStudentLeave({
                                ...studentLeave,
                                description: ev.target.value,
                            });
                        }}
                    />
                </Grid>

                <Grid item xs={12} mt={1}>
                    <Button
                        type="submit"
                        variant="contained"
                        disabled={feedback.loading}
                        endIcon={
                            feedback.loading ? (
                                <CircularProgress size="1em" />
                            ) : null
                        }
                    >
                        {feedback.loading
                            ? "Add Student Leave..."
                            : "Add Student Leave"}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

export default AddStudentLeave;
