import { ViewColumn } from "@mui/icons-material";
import {
    Alert,
    Checkbox,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Autocomplete as MuiAutoComplete,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
    useTheme,
} from "@mui/material";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useGetAPI } from "../../../services/Hooks";
import { SessionService } from "../../../services/SessionService";
import { StudentService } from "../../../services/StudentService";
import { AppContext } from "../../AppContext";
import { Autocomplete } from "../../AutoComplete";
import PrintTable from "../../Export/PrintTable";
import Footer from "../../Settings/Footer/Footer";
import { HeaderStructure } from "../../Settings/Header/HeaderStructure";

const columnOptions = [
    { label: "Reg No.", value: "admissionNumber" },
    { label: "Enroll No.", value: "enrollmentNo" },
    { label: "File No.", value: "fileNo" },
    { label: "Name", value: "name" },
    { label: "Father Name", value: "fatherName" },
];

const orderOptions = [
    { label: "Ascending", value: "ASC" },
    { label: "Descending", value: "DESC" },
];

const BlankAttendanceList = () => {
    const { user, feedback, setFeedback } = useContext(AppContext);
    const tableRef = useRef<HTMLTableElement>(null);
    const [printMode, setPrintMode] = useState(false);
    const theme = useTheme();
    const [columns, setColumns] = useState([
        { id: "SrNo", label: "Sr No.", visible: true },
        { id: "id", label: "ID", visible: true },
        { id: "RegNo", label: "Reg No.", visible: true },
        { id: "enrollmentNo", label: "Enroll No.", visible: true },
        { id: "fileNo", label: "File No.", visible: true },
        { id: "name", label: "Name", visible: true },
        { id: "fatherName", label: "Father Name", visible: true },
        { id: "fatherMobile", label: "Father Mobile", visible: true },
        { id: "lastAttendance", label: "Last Attendance", visible: true },
        { id: "currentAttendance", label: "Current Attendance", visible: true },
        { id: "grossAttendance", label: "Gross Attendance", visible: true },
        { id: "fine", label: "Fine", visible: true },
    ]);
    const [filter, setFilter] = useState<any>({
        startDate: new Date().toISOString().split("T")[0],
        endDate: new Date().toISOString().split("T")[0],
    });

    const [lastSession, setLastSession] = useState<any>();

    const [studentData, setStudentData] = useState<any>();

    const [pagination, setPagination] = useState({
        page: 0,
        limit: 0,
        count: 0,
    });

    const [selectedColumn, setSelectedColumn] = useState("admissionNumber");
    const [selectedOrder, setSelectedOrder] = useState("ASC");

    const [selected, setSelected] = useState<any>({
        campus:
            user?.baseUser?.userRegions?.length > 1
                ? ""
                : user?.baseUser?.userRegions[0]?.campus?.id,
        fromSession: "",
        fromClassroom: "",
        fromSection: "",
        toCampus: "",
        toSession: "",
        toClassroom: "",
        toSection: "",
        exclude: [],
    });

    const [students, studentCount, studentStatus, studentMessage] = useGetAPI(
        StudentService.url,
        pagination.page + 1,
        pagination.limit,
        {
            classroom: selected.fromClassroom.id,
            section: selected.fromSection.id,
            column: selectedColumn,
            direction: selectedOrder,
        },
        [
            selected.fromClassroom,
            selected.fromSection,
            selectedOrder,
            selectedColumn,
        ],
        [selected.fromClassroom, selected.fromSection]
    );

    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenuOpen = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const headerVariables = useMemo(() => {
        if (!user?.baseUser?.userRegions.length) return;

        console.log(user.baseUser.userRegions);

        try {
            return JSON.parse(user?.baseUser?.userRegions[0].campus.header);
        } catch (error) {
            return {
                campusName: false,
                campusAddress: false,
                clientEmail: false,
                clientPhone: false,
            };
        }
    }, [user]);

    const handleColumnVisibilityChange = (id: any) => {
        setColumns((prev) =>
            prev.map((col) =>
                col.id === id ? { ...col, visible: !col.visible } : col
            )
        );
    };

    function getDateRange(startDate: string, endDate: string) {
        const start = new Date(startDate);
        const end = new Date(endDate);
        const dateArray = [];

        while (start <= end) {
            dateArray.push(
                new Date(start).toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "short",
                    year: "numeric",
                })
            );
            start.setDate(start.getDate() + 1);
        }

        return dateArray;
    }

    const LastSessionFromCampus = async () => {
        try {
            const [data, err] = await SessionService.getLastSession(1, 1, {
                campus: selected.campus.id,
            });

            if (err) {
                console.error("Error fetching last session:", err);
                return;
            }

            if (data && data.rows.length) {
                setSelected((prevFilter: any) => ({
                    ...prevFilter,
                    fromSession: data.rows[0],
                }));
                setLastSession(data.rows[0]);
            } else {
                setSelected((prevFilter: any) => ({
                    ...prevFilter,
                    session: "",
                }));
                setLastSession(null);
            }
        } catch (error) {
            console.error("Unexpected error in LastSession:", error);
        }
    };

    useEffect(() => {
        if (selected.campus) {
            LastSessionFromCampus();
        }
    }, [selected.campus]);

    useEffect(() => {
        setPagination((_p) => ({ ..._p, count: studentCount }));
    }, [studentCount]);

    useEffect(() => {
        if (!filter.startDate || !filter.endDate) return;

        const dateRange = getDateRange(filter.startDate, filter.endDate);

        const dynamicDateColumns = dateRange.map((date, index) => ({
            id: `date-${index}`,
            label: date,
            visible: true,
        }));

        setColumns((prevColumns) => [
            ...prevColumns.filter((col) => !col.id.startsWith("date-")),
            ...dynamicDateColumns,
        ]);
    }, [filter.startDate, filter.endDate]);

    useEffect(() => {
        if (!students) return;

        const dateRange =
            filter.startDate && filter.endDate
                ? getDateRange(filter.startDate, filter.endDate)
                : [];

        setStudentData(
            students.map((item: any, idx: any) => {
                const dateAttendance = dateRange.reduce(
                    (acc: any, date, index) => {
                        acc[`date-${index}`] = ""; // Replace with attendance data if available
                        return acc;
                    },
                    {}
                );

                return {
                    SrNo: idx + 1,
                    id: item.id,
                    name: item.baseUser.name,
                    RegNo: item.admissionNumber,
                    enrollmentNo: item.enrollmentNo,
                    fileNo: item.fileNo,
                    fatherName: item.fatherName,
                    fatherMobile: item.fatherMobile,
                    lastAttendance: "",
                    currentAttendance: "",
                    grossAttendance: "",
                    fine: "",
                    ...dateAttendance,
                };
            })
        );
    }, [students, filter.startDate, filter.endDate]);

    return (
        <div>
            <Grid container spacing={1}>
                {user?.baseUser?.userRegions?.length > 1 && (
                    <Grid item xs={12} md={4}>
                        <Autocomplete
                            api="/org/campus"
                            setOutput={(c) =>
                                setSelected({
                                    ...selected,
                                    campus: c || "",
                                })
                            }
                            label="Campus"
                            labelKey="name"
                            textFieldProps={{
                                variant: "outlined",
                                size: "small",
                            }}
                        />
                    </Grid>
                )}

                <Grid item xs={12} md={4}>
                    <Autocomplete
                        api="/org/session"
                        setOutput={(c) =>
                            setSelected({
                                ...selected,
                                fromSession: c,
                            })
                        }
                        label="Session"
                        labelKey="name"
                        textFieldProps={{ variant: "outlined", size: "small" }}
                        apiParams={{
                            campus: selected.campus,
                            status: "active",
                        }}
                        defaultValue={lastSession}
                        key={lastSession?.id}
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <Autocomplete
                        api="/org/academics/classroom"
                        setOutput={(c) =>
                            setSelected({
                                ...selected,
                                fromClassroom: c,
                            })
                        }
                        label="Class"
                        labelKey="name"
                        textFieldProps={{ variant: "outlined", size: "small" }}
                        apiParams={{
                            campus: selected?.campus?.id,
                            status: "active",
                        }}
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <Autocomplete
                        api="/org/academics/section"
                        setOutput={(c) =>
                            setSelected({
                                ...selected,
                                fromSection: c,
                            })
                        }
                        label="Section"
                        labelKey="name"
                        textFieldProps={{ variant: "outlined", size: "small" }}
                        apiParams={{
                            classroom: selected?.fromClassroom?.id,
                            status: "active",
                        }}
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <TextField
                        type="date"
                        size="small"
                        fullWidth
                        label="Start Date"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) =>
                            setFilter({
                                ...filter,
                                startDate: e.target.value,
                            })
                        }
                        defaultValue={new Date().toISOString().split("T")[0]}
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <TextField
                        type="date"
                        size="small"
                        fullWidth
                        label="End Date"
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) =>
                            setFilter({
                                ...filter,
                                endDate: e.target.value,
                            })
                        }
                        variant="outlined"
                        defaultValue={new Date().toISOString().split("T")[0]}
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <MuiAutoComplete
                        options={columnOptions}
                        getOptionLabel={(option) => option.label}
                        onChange={(event, newValue) =>
                            setSelectedColumn(newValue?.value || "")
                        }
                        value={columnOptions.find(
                            (option) => option.value === selectedColumn
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Sort By"
                                variant="outlined"
                            />
                        )}
                        size="small"
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <MuiAutoComplete
                        options={orderOptions}
                        getOptionLabel={(option) => option.label}
                        onChange={(event, newValue) =>
                            setSelectedOrder(newValue?.value || "")
                        }
                        value={orderOptions.find(
                            (option) => option.value === selectedOrder
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Order"
                                variant="outlined"
                            />
                        )}
                        size="small"
                        fullWidth
                    />
                </Grid>
            </Grid>

            {selected.campus == "" ||
            selected.fromSession == "" ||
            selected.fromClassroom == "" ||
            selected.fromSection == "" ? (
                <Alert severity="info" style={{ width: "100%" }}>
                    Please Select a campus,session,classroom and section
                </Alert>
            ) : (
                <>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            padding: "8px",
                        }}
                    >
                        <IconButton onClick={handleMenuOpen}>
                            <ViewColumn
                                htmlColor={theme.palette.primary.main}
                            />
                        </IconButton>
                        <PrintTable
                            printMode={printMode}
                            setPrintMode={setPrintMode}
                            componentRef={tableRef}
                        />
                    </div>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                        PaperProps={{
                            style: {
                                maxHeight: 400,
                                width: "250px",
                                backgroundColor: "#5C4E3A",
                                color: "#FFCC33",
                            },
                        }}
                    >
                        {columns.map((col) => (
                            <MenuItem key={col.id}>
                                <Checkbox
                                    checked={col.visible}
                                    style={{ color: "#FFCC33" }}
                                    onChange={() =>
                                        handleColumnVisibilityChange(col.id)
                                    }
                                />
                                {col.label}
                            </MenuItem>
                        ))}
                    </Menu>
                    <TableContainer component={Paper} ref={tableRef}>
                        {printMode && (
                            <HeaderStructure
                                selected={headerVariables}
                                campus={user.baseUser.userRegions[0].campus}
                                leftImage={"/images/icon.png"}
                                color={"black"}
                                additionalRow={`Attendance List of class (${selected?.fromClassroom?.name})  And  section (${selected?.fromSection?.name})`}
                            />
                        )}
                        <Table>
                            <TableHead
                                sx={(theme) => ({
                                    background: theme.palette.background.paper,
                                })}
                            >
                                <TableRow>
                                    {columns.map(
                                        (col) =>
                                            col.visible && (
                                                <TableCell
                                                    variant="head"
                                                    key={col.id}
                                                    style={{
                                                        border: "1px solid ",
                                                        textAlign: [
                                                            "Name",
                                                            "Father Name",
                                                        ].includes(col.label)
                                                            ? "left"
                                                            : "center",
                                                        minWidth: "fit-content",
                                                    }}
                                                >
                                                    <Typography noWrap>
                                                        {col.label}
                                                    </Typography>
                                                </TableCell>
                                            )
                                    )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {studentData.map((row: any, index: any) => (
                                    <TableRow key={index}>
                                        {columns.map(
                                            (col) =>
                                                col.visible && (
                                                    <TableCell
                                                        size="small"
                                                        key={col.id}
                                                        style={{
                                                            border: "1px solid",
                                                            textAlign: [
                                                                "Name",
                                                                "Father Name",
                                                            ].includes(
                                                                col.label
                                                            )
                                                                ? "left"
                                                                : "center",
                                                            maxWidth:
                                                                "fit-content",
                                                        }}
                                                    >
                                                        <Typography noWrap>
                                                            {row[col.id] || ""}
                                                        </Typography>
                                                    </TableCell>
                                                )
                                        )}
                                    </TableRow>
                                ))}
                            </TableBody>
                            {printMode && (
                                <Footer span={selectedColumn.length + 1} />
                            )}
                        </Table>
                    </TableContainer>{" "}
                </>
            )}
        </div>
    );
};

export default BlankAttendanceList;
